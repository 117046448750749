import { useState, FC } from 'react';
import { createContext } from 'react';

type IType = {
    isPlateformCpts: boolean;
    setIsPlateformCpts: (val: any) => void;

    fetchingPlateform: boolean;
    setFetchingPlateform: (val: any) => void;

    isCoordonatorFromPlateform: boolean;
    setIsCoordonatorFromPlateform: (val: any) => void;
};

export const PlateformTypeContext = createContext<IType>({
    isPlateformCpts: false,
    setIsPlateformCpts: () => {},

    fetchingPlateform: false,
    setFetchingPlateform: () => {},

    isCoordonatorFromPlateform: false,
    setIsCoordonatorFromPlateform: () => {},
});

const PlateformTypeProvider: FC<any> = (props) => {
    const { children } = props;
    const [isPlateformCpts, setIsPlateformCpts] = useState(false);
    const [fetchingPlateform, setFetchingPlateform] = useState(false);
    const [isCoordonatorFromPlateform, setIsCoordonatorFromPlateform] = useState(false);

    return (
        <PlateformTypeContext.Provider
            value={{
                isPlateformCpts,
                setIsPlateformCpts,

                fetchingPlateform,
                setFetchingPlateform,

                isCoordonatorFromPlateform,
                setIsCoordonatorFromPlateform,
            }}
        >
            {children}
        </PlateformTypeContext.Provider>
    );
};

export default PlateformTypeProvider;
