import { FC, useContext, useEffect, useState } from 'react';
import useStyles from './styles';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors } from 'src/utils/constants/baseStyles';
import { ProgramContext } from 'src/providers/ProgramProvider';
import { ProgramItem } from 'src/interfaces/interfaces';
import ProgramService from 'src/services/Program/program.service';
import { useHistory } from 'react-router-dom';
import { LabelWithValue } from '../utils';
import { Spacer } from 'src/components/Spacer/Spacer';
import { PatientContext } from 'src/providers/PatientProvider';
import { fullName } from 'src/utils/utils';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { Box} from '@mui/material';
import { PatientService } from 'src/services/Patients';
import { toast } from 'react-toastify';
import { ServerError } from 'src/utils/constants/constants';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import CustomModal from 'src/components/CustomModal';

export const GlobalActivity: FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const { setCurrentLocationPathname, setCurrentPatientId } = useContext(PatientContext);
    const { currentProgram } = useContext(ProgramContext);
    const [program, setProgram] = useState<ProgramItem | undefined>(currentProgram);
    const [activity, setActivity] = useState<any>(undefined);
    const [patients, setPatients] = useState<any>([]);
    const [modalConfirmationVisible, setModalConfirmationVisible] = useState<boolean>(false);
    const toggleModalConfirmation = () => setModalConfirmationVisible(!modalConfirmationVisible);
    const [patientSelected, setPatientSelected] = useState<string>();

    useEffect(() => {
        if (!currentProgram) {
            const splitted = history.location.pathname.split('/');
            if (splitted.length >= 4) {
                const id = parseInt(splitted[2]);
                ProgramService.getProgramById(id).then((response) => {
                    const programItem = response.data?.data;
                    if (programItem) {
                        setProgram(programItem);
                    }
                });
                getActivity(id);
            }
        } else {
            getActivity(currentProgram.id);
        }

        handleFetchPatients();
    }, []);

    const getActivity = (id: number) => {
        ProgramService.getProgramsActivity(id).then((response) => {
            const data = response?.data?.data;
            if (data) {
                setActivity(data);
            }
        });
    };

    const handleFetchPatients = (query?: string) => {
        PatientService.getPatients(query)
            .then((response) => {
                const data = response?.data?.data;
                if (data) {
                    setPatients(data);
                }
            })
            .catch((e) => {
                toast.error(ServerError.unknown);
            });
    };

    const patientList = (id: number) => {
        const professionnalPatients = Array.from(patients,(p : any)=> p?.id);
        const patient = program?.patients?.find((p : any)=> p?.id === id);
        if(professionnalPatients?.includes(id)){
            setCurrentPatientId(id);
            history.push(`/patients/list/${id}/profile`);
            setCurrentLocationPathname(history.location.pathname);
        }else{
            setPatientSelected(patient?.lastName+" "+patient?.firstName)
            setModalConfirmationVisible(true)
        }
    };

    return (
        <>
        <CustomModal
            title={`Impossible de voir les détails de ce patient, ${patientSelected} ne figure pas dans votre liste de patients`}
            toggleModal={setModalConfirmationVisible}
            open={modalConfirmationVisible}
            size="small"
            footer={<div style={{ display: 'flex', justifyContent: 'center' }}>
                <CustomLoadingButton
                    handleClick={setModalConfirmationVisible}
                    width={350}
                    label="Fermer" />
            </div>} children={undefined}        >
        </CustomModal>

        <div className={classes.globalActivityRow}>
            <div style={{minWidth: "40%",marginRight:"5px",height: '100%'}} className={classes.card}>
                <CustomText style={{ textAlign: 'center' }} color={baseColors.green.primary}>
                    Liste des patients
                </CustomText>
                {
                    program ? 
                    (
                        program?.patients?.length > 0 ? (
                            <div style={{ overflowY: 'auto', height: '100%', marginTop: 20 }}>
                                {program?.patients?.map((patient, i) => (
                                    <div key={i} onClick={() => patientList(patient.id)} style={{ cursor: 'pointer', marginBottom: '3px' }}>
                                        <CustomText key={i}>{fullName(patient.firstName, patient.lastName)}</CustomText>
                                    </div>
                                ))}
                            </div>
                        ): (
                            <CustomText style={{ textAlign: 'center',paddingTop:"15px" }} color={baseColors.green.primary}>
                                Aucun patient trouvé
                            </CustomText>
                        )
                        
                    ):(
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '15%' }}>
                            <CustomCircleLoader />
                        </Box>
                    )
                }
            </div>

            
                <div style={{ overflowY: 'auto', height: '100%',minWidth: "40%" }} className={classes.card}>
                    
                    <CustomText style={{ textAlign: 'center' }} color={baseColors.green.primary}>
                        Activité brute
                    </CustomText>
                    {activity ? (
                        <>
                        <Spacer height={20} />
                        <LabelWithValue
                            label="Patients ayant fini le programme"
                            value={activity['patients-finished-program']}
                        />
                        <Spacer height={10} />
                        <LabelWithValue
                            label="Patients engagés dans le programme"
                            value={activity['patients-engaged-program']}
                        />
                        <Spacer height={10} />
                        <LabelWithValue
                            label="Ratio"
                            value={activity['ratio-engaged-finished'] ? activity['ratio-engaged-finished'] + '%' : ''}
                        />
                        <Spacer height={40} />
                        <CustomText style={{ textAlign: 'center' }} color={baseColors.green.primary}>
                            Activité Distanciel
                        </CustomText>
                        <Spacer height={20} />
                        <LabelWithValue
                            label="Séances du programme “en distanciel” proposées aux patients"
                            value={activity['seances-program-distanciel']}
                        />
                        <Spacer height={10} />
                        <LabelWithValue
                            label="Ratio séances du programme “en distanciel” / séances totales du programme"
                            value={
                                activity['ratio-seances-program-distanciel-total']
                                    ? activity['ratio-seances-program-distanciel-total'] + '%'
                                    : ''
                            }
                        />
                        <Spacer height={10} />
                        <LabelWithValue
                            label="Ratio séances prestées dans l'année “en distanciel” / total des séances prestées dans l'année"
                            value={
                                activity['ratio-seances-annee-distanciel-total']
                                    ? activity['ratio-seances-annee-distanciel-total'] + '%'
                                    : ''
                            }
                        />
                    </>
                ):(
                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '15%' }}>
                        <CustomCircleLoader />
                    </Box>
                )}
                </div>
 
        </div>
        </>
    );
};
