import React, { FC, useState, useEffect, Dispatch, SetStateAction } from 'react';
import CustomModal from '../../../../components/CustomModal';
import { Grid, IconButton } from '@mui/material';
import { CustomText } from '../../../../components/Text/CustomText';
import { InputField } from '../../../../components/InputField/InputField';
import { isNotBlank } from '../../../../utils/helpers/InputValidator';
import { CustomSelect } from '../../../../components/InputField/CustomSelect';
import { StateFromMarkdown, TextEditor } from '../../../../components/CustomEditor/TextEditor';
import { EditorState } from 'draft-js';
import { updatePost, postNewPosts, getProgramTypesList, getAxisList, getCategoryPostsList, getTypePostsList } from '../../../../services/Posts';
import { ImageRounded } from '@mui/icons-material';
import { message } from '../../../../utils/helpers/helpers';
import CustomCircleLoader from './../../../../components/CustomLoader/CustomCircleLoader';
import { toast } from 'react-toastify';
import { VideoUploader } from './VideoUploader';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { Buttons, baseScreenHeight } from 'src/utils/constants/baseStyles';
import { ArticleRelatedGoals } from './ArticleRelatedGoals';
import { ArticlePayload } from 'src/interfaces/interfaces';
import { CustomCheckBox } from 'src/components/InputField/CustomCheckBox';

interface ArticleFormProps {
    selectedPostData?: any;
    modalOpen: boolean;
    toggleModal: any;
    setUpdateList?: any;
    article: ArticlePayload;
    setArticle: Dispatch<SetStateAction<ArticlePayload>>;
    customTitle?: string;
    clearInput?: any;
    vidsource: any;
    setVidsource?: any;
    videoLoader?: any;
    setVideoLoader?: any;
    setJustAddedArticle?: Dispatch<SetStateAction<any>>;
    callBack?: Function;
}

export const ArticleForm: FC<ArticleFormProps> = (props) => {
    const {
        modalOpen,
        toggleModal,
        setUpdateList,
        selectedPostData,
        article,
        setArticle,
        customTitle,
        setVidsource,
        videoLoader,
        setVideoLoader,
        clearInput,
        setJustAddedArticle,
        callBack,
    } = props;

    const [lockArticleInput, setLockArticleInput] = useState(false);
    const [loadingArticleForm, setLoadingArticleForm] = useState(false);
    const [programCategory, setProgramCategory] = useState<Object[]>([]);

    const [axisList, setAxisList] = useState<Object[]>([]);
    const axisData = [{ id: 0, label: "Choisir l'axe", value: '' }, ...axisList];

    const [categoryList, setCategoryList] = useState<Object[]>([]);
    const categoryData = [{ id: 0, label: 'Choisir la catégorie', value: '' }, ...categoryList];

    const [typeList, setTypeList] = useState<Object[]>([]);
    const typeData = [{ id: 0, label: 'Choisir le type', value: '' }, ...typeList];
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const programCategoryData = [{ id: 0, name: 'Choisir le type programme' }, ...programCategory];

    const [type, setType] = useState<number>();
    const [image, setImage] = useState<string>();
    const [video, setVideo] = useState<string>();
    const [postTraitmentMessage, setPostTraitementMessage] = useState<string>(message.articleAddedSuccessfully);
    const [uploadingLoader, setUploadingLoader] = useState(false);
    const [source, setSource] = useState('');
    const [imageSource, setImageSource] = useState('');

    const [uploadingFile, setUploadingFile] = useState(false);
    const [isImpactPatient, setIsImpactPatient] = useState(false);

    useEffect(() => {
        if (+selectedPostData?.id > 0) {
            setArticle((prev) => ({
                ...prev,
                title: selectedPostData?.title ?? '',
                content: selectedPostData?.content ?? '',
                axisId: selectedPostData?.axis?.id ?? '',
                postCategoryId: selectedPostData?.postCategory?.id,
                postTypeId: selectedPostData?.postType?.id,
                programTypeId: selectedPostData?.programType?.id ?? '',
                image: {
                    fileContent: '',
                },
                video: {
                    fileContent: '',
                    uploadedFileName: selectedPostData?.video?.name,
                    thumbnailFileContent: '',
                },
                goalsIds: selectedPostData?.goals?.map((goal: { id: number }) => goal.id),
            }));
            setImage(selectedPostData?.image?.url);
            setVideo(selectedPostData?.video?.url);
            const contentStateFromMarkdown = StateFromMarkdown(selectedPostData?.content);
            setEditorState(() => EditorState.createWithContent(contentStateFromMarkdown));
        } else {
            setEditorState(() => EditorState.createEmpty());
        }
    }, [selectedPostData]);

    useEffect(() => {
        const articleId = +article?.postTypeId! || 0;
        setType(articleId);
        handleFetchProgramCategory();
    }, [article]);

    const successfully = (res: any) => {
        if (selectedPostData?.id > 0) {
            toast.success('Contenu modifié avec succès.');
            toggleModal();
            setSource('');
            setImageSource('');
            setVidsource(undefined);
            setVideoLoader(true);
        } else {
            if (res.data.code === 200) {
                toast.success('Contenu ajouté avec succès.');
                setArticle((old: any) => ({ ...old, title: '' }));
                toggleModal();
                setUpdateList?.((prev: any) => !prev);
                setSource('');
                setImageSource('');
                setVidsource(undefined);
                setVideoLoader(true);
                setJustAddedArticle?.((prev:any)=> ([...prev,res?.data?.data]))
                callBack?.();
            } else if (res.data.code === 400) {
                toast.warn("Contenu existe déjà dans l'application.");
                setPostTraitementMessage(message.articleAlreadyExist);
                setArticle(article);
            }
        }
        setUpdateList?.((prev: any) => !prev);
        setLoadingArticleForm(false);
        setLockArticleInput(false);
        clearInput?.();
        setEditorState(() => EditorState.createEmpty());
        setSource('');
        setImageSource('');
        setVidsource(undefined);
        setVideoLoader(true);
    };

    const failed = () => {
        if (selectedPostData?.id > 0) {
            toast.error('Erreur de modification du contenu');
        } else {
            toast.error("Erreur d'ajout nouveau contenu");
        }
        setPostTraitementMessage(message.articleNotInserted);
        setLoadingArticleForm(false);
        setLockArticleInput(false);
        clearInput?.();
        setEditorState(() => EditorState.createEmpty());
        setSource('');
        setImageSource('');
        setVidsource(undefined);
        setVideoLoader(true);
    };

    const performAddPost = () => {
        setLoadingArticleForm(true);
        setLockArticleInput(true);

        if (selectedPostData?.id > 0) {
            updatePost(selectedPostData?.id, article)
                .then((res) => successfully(res))
                .catch(() => failed());
        } else {
            postNewPosts(article)
                .then((res) => successfully(res))
                .catch(() => failed());
        }
    };

    const handleChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        let files = event.currentTarget.files;
        if (files && files?.length > 0) {
            let file = files[0];
            const obj_url = URL.createObjectURL(file);
            setImageSource(obj_url);
            let allowedExtension = ['image/png', 'image/jpeg', 'image/jpg'];
            if (!allowedExtension.includes(file.type)) {
                alert('image non supportée , veuillez importer des .jpeg, .jpg, .png');
            } else {
                const reader = new FileReader();
                reader.onload = _handleReaderLoaded;
                reader.readAsBinaryString(file);
            }
        }
    };

    const handleFetchProgramCategory = () => {
        getProgramTypesList()
            .then((response) => {
                const posts = response?.data?.data?.items;
                if (posts) {
                    setProgramCategory(posts);
                }
            })
            .catch((error) => console.log(error));
    };

    const _handleReaderLoaded = (readerEvt: any) => {
        let binaryString = readerEvt.target.result;
        let fileContent = `data:image/jpeg;base64,${btoa(binaryString)}`;
        setImage(`data:image/jpeg;base64,${btoa(binaryString)}`);
        setArticle((old: any) => ({ ...old, image: { ...old.image, fileContent: fileContent } }));
    };

    const validVideoDataLoader = () => videoLoader === false && videoLoader !== undefined;

    const validData = () =>
        validVideoDataLoader() &&
        isNotBlank(article?.title) &&
        article?.axisId !== 0 &&
        article?.postCategoryId !== 0 &&
        article?.postTypeId === 3 &&
        article?.video?.uploadedFileName !== '';

    const validOtherData = () => {
        let isValid = false;
        if(isNotBlank(article?.title) && article?.axisId != 0 && article?.postCategoryId != 0 && article?.postTypeId != 0){
            isValid = true;
        }
             
        if(article?.postTypeId === 2 && article?.image?.fileContent === '')
            isValid = false;
        else if(article?.postTypeId === 1 && article?.content.trim() === '')
            isValid = false;

        return isValid;
    }

    const parseAxisData = (axis: any[]) => {
        const rows: any[] = Array.from(axis, (axe) => ({
            id: axe.id,
            label: axe.name,
            value: axe.code,
        }));
        rows.splice(6, 1);
        rows.splice(5, 1);
        rows.splice(4, 1);
        setAxisList(rows);
    };

    const handleFetchAxis = () => {
        getAxisList()
            .then((response) => {
                const axis = response;
                if (axis.items.length > 0) {
                    parseAxisData(axis.items);
                }
            })
            .catch((error) => console.log(error));
    };

    const parseCategoriesData = (categories: any[]) => {
        const rows: any[] = Array.from(categories, (category) => ({
            id: category.id,
            label: category.name,
            value: category.code,
        }));
        setCategoryList(rows);
    };
    
    const handleFetchCategory = () => {
        getCategoryPostsList()
            .then((response) => {
                const categories = response;
                if (categories.items.length > 0) {
                    parseCategoriesData(categories.items);
                }
            })
            .catch((error) => console.log(error));
    };

    const parseTypesData = (types: any[]) => {
        const rows: any[] = Array.from(types, (type) => ({
            id: type.id,
            label: type.name,
            value: type.code,
        }));
        setTypeList(rows);
    };
    const handleFetchType = () => {
        getTypePostsList()
            .then((response) => {
                const types = response;
                if (types.items.length > 0) {
                    parseTypesData(types.items);
                }
            })
            .catch((error) => console.log(error));
    };

    useEffect(()=>{
        handleFetchAxis();
        handleFetchCategory();
        handleFetchType();
    },[])

    useEffect(() => {
        article?.goalsIds?.length > 0 && setArticle((prev) => ({ ...prev, isImpactPatient: isImpactPatient ? 1 : 0 }));
    }, [isImpactPatient]);

    useEffect(()=>{
        article?.goalsIds?.length === 0 ? setIsImpactPatient(false) : setIsImpactPatient(true);
    },[article?.goalsIds])

    const actualTypeData: any = selectedPostData?.id > 0 ? article?.postTypeId : 0;
    const axisType: any = selectedPostData?.id > 0 ? article?.axisId : 0;
    const actualCategory: any = selectedPostData?.id > 0 ? article?.postCategoryId : 0;
    const actualProgramType: any = selectedPostData?.id > 0 ? article?.programTypeId : 0;

    const handleUpdateSource = (newSource: any) => setVidsource(newSource);
    const handleUpdateLoaderState = (newState: boolean) => setVideoLoader(newState);

    const btnLabel = selectedPostData?.id > 0 ? 'Modifier contenu' : 'Ajouter un nouveau contenu';
    const modalTitle = customTitle ? customTitle : selectedPostData?.id > 0 ? 'Modifier contenu' : 'Nouveau contenu'
    const isAddFromGoal = Boolean(customTitle && customTitle?.length > 0);
    
    return (
        <CustomModal
            withMinHeight
            width={600}
            height={baseScreenHeight.newEventModal}
            title={modalTitle}
            toggleModal={toggleModal}
            open={modalOpen}
            footer={
                <>
                    {
                        article?.goalsIds?.length > 0 && 
                        <Grid container direction="row" spacing={2} width={600}>
                            <Grid item>
                                <CustomCheckBox
                                    fontSize={14}
                                    onClick={() => setIsImpactPatient((prev) => !prev)}
                                    checked={article?.isImpactPatient === 1}
                                    label='Tous les contenus de cet objectif seront accessibles par les patients ayant déjà cet objectif dans son programme en cours.'
                                    lineClamp={2}
                                />
                            </Grid>
                            <Grid item style={{ flex: 1 }} />
                        </Grid>
                    }
                    <div style={{ display: 'flex', justifyContent: 'center', margin: Buttons.margin }}>
                        <CustomLoadingButton
                            width={Buttons.width}
                            label={btnLabel}
                            disabled={uploadingFile || (type === 3 ? !validData() : !validOtherData())}
                            loading={loadingArticleForm}
                            handleClick={performAddPost}
                        />
                    </div>
                </>
            }
        >
            <Grid lg={12} container direction="row" alignItems={'center'} style={{}}>
                <Grid item>
                    <CustomText style={{ minWidth: '200px' }}>Titre du contenu :</CustomText>
                </Grid>
                <InputField
                    label={''}
                    disabled={lockArticleInput}
                    defaultValue={article?.title ?? ''}
                    value={article?.title ?? ''}
                    onChange={(value: any) => {
                        setArticle((old: any) => ({ ...old, title: value }));
                    }}
                    validator={isNotBlank}
                    errorMessage="Title obligatoire"
                    placeholder={'Entrer le titre'}
                    width={360}
                    idSuffix="title-article"
                />
            </Grid>

            <Grid lg={12} container direction="row" alignItems={'center'} marginBottom="20px">
                <Grid item>
                    <CustomText style={{ minWidth: '200px' }}>Type du contenu :</CustomText>
                </Grid>
                <Grid item style={{ width: '60%' }}>
                    <CustomSelect
                        onChange={(value: any) => {
                            setArticle((old: any) => ({ ...old, postTypeId: value }));
                        }}
                        label=""
                        labelKey={''}
                        items={typeData}
                        defaultValue={actualTypeData ?? 0}
                        idSelect="type"
                        width="100%"
                    />
                </Grid>
            </Grid>

            {type === 2 && (
                <Grid lg={12} container direction="row" alignItems={'center'} marginBottom="20px">
                    <Grid item>
                        <CustomText style={{ minWidth: '200px' }}>Importer image :</CustomText>
                    </Grid>
                    <div>
                        <input
                            accept="image/*"
                            id="icon-button-file"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleChangeImage}
                        />
                        <label htmlFor="icon-button-file">
                            {selectedPostData?.id > 0 && image?.length !== 0 ? (
                                <div>
                                    <img alt="article-videos" src={`${image}`} style={{ width: 150, height: 150 }} />
                                    <IconButton
                                        style={{ marginBottom: '60%', marginLeft: 20 }}
                                        color="primary"
                                        aria-label="upload video"
                                        component="span"
                                    >
                                        <ImageRounded />
                                    </IconButton>
                                </div>
                            ) : (
                                <div>
                                    {imageSource && (
                                        <img
                                            alt="article-videos"
                                            src={`${imageSource}`}
                                            style={{ width: 150, height: 150 }}
                                        />
                                    )}
                                    <IconButton
                                        color="primary"
                                        aria-label="upload video"
                                        component="span"
                                        style={{ marginBottom: '60%', marginLeft: 20 }}
                                    >
                                        {uploadingLoader === true ? (
                                            <CustomCircleLoader />
                                        ) : (
                                            <IconButton color="primary" aria-label="upload video" component="span">
                                                <ImageRounded />
                                            </IconButton>
                                        )}
                                    </IconButton>
                                </div>
                            )}
                        </label>
                    </div>
                </Grid>
            )}

            {type === 3 && (
                <Grid lg={12} container direction="row" alignItems={'center'} marginBottom="20px">
                    <Grid item>
                        <CustomText style={{ minWidth: '200px' }}>Importer vidéo:</CustomText>
                    </Grid>
                    <Grid>
                        <VideoUploader
                            article={article}
                            setArticle={setArticle}
                            selectedPostData={selectedPostData}
                            video={video}
                            setVideo={video}
                            onUpdateSource={handleUpdateSource}
                            onUpdateLoading={handleUpdateLoaderState}
                            uploadingFile={uploadingFile}
                            setUploadingFile={setUploadingFile}
                        />
                    </Grid>
                </Grid>
            )}

            <Grid lg={12} container direction="row" alignItems={'center'} marginBottom="20px">
                <Grid item>
                    <CustomText style={{ minWidth: '200px' }}>Type du programme :</CustomText>
                </Grid>
                <Grid item style={{ width: '60%' }}>
                    <CustomSelect
                        onChange={(value: any) => setArticle((old: any) => ({ ...old, programTypeId: value }))}
                        label=""
                        labelKey={'name'}
                        disabled={lockArticleInput}
                        items={programCategoryData}
                        defaultValue={actualProgramType}
                        idSelect="programme"
                        width="100%"
                    />
                </Grid>
            </Grid>

            <Grid lg={12} container direction="row" alignItems={'center'} marginBottom="20px">
                <Grid item>
                    <CustomText style={{ minWidth: '200px' }}>Axe :</CustomText>
                </Grid>
                <Grid item style={{ width: '60%' }}>
                    <CustomSelect
                        onChange={(value: any) => {
                            setArticle((old: any) => ({ ...old, axisId: value }));
                        }}
                        label=""
                        labelKey={''}
                        disabled={lockArticleInput}
                        items={axisData}
                        defaultValue={axisType}
                        idSelect="axe"
                        width="100%"
                    />
                </Grid>
            </Grid>

            <Grid lg={12} container direction="row" alignItems={'center'} marginBottom="20px">
                <Grid item>
                    <CustomText style={{ minWidth: '200px' }}>Catégorie du contenu :</CustomText>
                </Grid>
                <Grid item style={{ width: '60%' }}>
                    <CustomSelect
                        onChange={(value: any) => {
                            setArticle((old: any) => ({ ...old, postCategoryId: value }));
                        }}
                        label=""
                        labelKey={''}
                        disabled={lockArticleInput}
                        defaultValue={actualCategory}
                        items={categoryData}
                        idSelect="categorie"
                        width="100%"
                    />
                </Grid>
            </Grid>

            {
                !isAddFromGoal && 
                <ArticleRelatedGoals
                    article={selectedPostData}
                    setPayload={setArticle}
                    lockWorkshopInput={lockArticleInput}
                    payload={article}
                />
            }

            <Grid lg={12} container direction="row" alignItems="center" marginBottom="20px">
                <Grid item>
                    <CustomText style={{ minWidth: '200px' }}>Description :</CustomText>
                </Grid>

                <TextEditor
                    setEditorState={setEditorState}
                    editorState={editorState}
                    handleChange={(value: any) => {
                        setArticle((old: any) => ({ ...old, content: value }));
                    }}
                    data={article?.content}
                />
            </Grid>

        </CustomModal>
    );
};
