import React, { useEffect } from 'react';
import 'draft-js/dist/Draft.css';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { baseColors } from 'src/utils/constants/baseStyles';
import { stateFromMarkdown } from 'draft-js-import-markdown';
import { stateToMarkdown } from 'draft-js-export-markdown';
import { convertToRaw } from 'draft-js';

export const StateFromMarkdown = (text) => {
    return stateFromMarkdown(text);
};

export const CustomTextEditor = ({
    handleChange, 
    editorState, 
    setEditorState, 
    data, 
    heightEditorStyle='15vh', 
    maxHeightEditorStyle='50vh',
    readOnly= false,
    isJsonData=false,
}) => {
    const editor = React.useRef(null);
    useEffect(() => {
        if (editorState != undefined) {
            const contentState = editorState.getCurrentContent();
            const raw = convertToRaw(contentState);
            const markdown = stateToMarkdown(contentState);
            if(isJsonData){
                const strData = JSON.stringify({raw, markdown});
                handleChange(strData);
            }else{
                handleChange(markdown);
            }
        }
    }, [editorState]);


    return (
        <div
            style={{
                border: readOnly ? '0px' : '1px solid ' + baseColors.green.dark,
                minHeight: '25vh',
                maxHeight: maxHeightEditorStyle,
                cursor: 'text',
                marginTop: readOnly ? 0 : 40,
                background: 'white',
                padding: readOnly ? 0 : 20,
                borderRadius: 10,
                width: '100%',
            }}
        >
            <Editor
                ref={editor}
                readOnly={readOnly}
                toolbarHidden={readOnly}
                showSuggestions
                editorState={editorState}
                onEditorStateChange={setEditorState}
                mention={{
                    separator: ' ',
                    trigger: '@',
                    suggestions: data,
                }}
                toolbar={{
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                }}
                editorStyle={{
                    height: heightEditorStyle
                }}
            />
        </div>
    );
};
