import { FC, useContext, useEffect, useState } from 'react';
import useStyles, { sxStyles } from './styles';
import { Button, IconButton, Typography } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { useHistory } from 'react-router';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import useEducationalDiagnosis from 'src/hooks/useEducationalDiagnosis';
import AxisLoaderContainer from './AxisLoaderContainer';
import { Spacer } from 'src/components/Spacer/Spacer';
import DEQuestionResponses from './DEQuestionResponses';
import { CustomText } from 'src/components/Text/CustomText';
import { useFetchUserPrograms } from 'src/hooks/useFetchUserPrograms';
import PatientProgram from './PatientProgram';
import { defaultObservationPayload, disablePostObservationBtn, handlePostDEObservation, performUpdateUserSurveys } from '../Conclusion/Logic';
import DieteticDEQuestionResponses from './DieteticDEQuestionResponses';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { GroupeByKeyValue, remoVeAdditionalTimeZone } from 'src/utils/utils';
import DuoIcon from '@mui/icons-material/Duo';
import CallEndIcon from '@mui/icons-material/CallEnd';
import { VisioContentContext } from 'src/providers/VisioContentProvider';
import EditResponseContainer from './EditResponse/EditResponseContainer';
import { UpdateUserSurvayPayload } from 'src/interfaces/interfaces';
import CustomVisioFramModal from 'src/components/CustomModal/CustomVisioFramModal';
import { UserContext } from 'src/providers/UserProvider';

interface AxisContainerProps {
    title: string;
    position: number;
    prevScreenPath: string;
    nextScreenPath: string;
    axisId: number;
    patientId: number;
    educationalDiagnosisId: number;
    isDietetic?: boolean;
    disablePrev?: boolean;
}

const AxisContainer: FC<AxisContainerProps> = ({
    educationalDiagnosisId,
    title,
    prevScreenPath,
    nextScreenPath,
    axisId,
    patientId,
    isDietetic,
    disablePrev,
}) => {
    const classes = useStyles();
    const history = useHistory();

    const [payload, setPayload] = useState(defaultObservationPayload);
    const [posting, setPosting] = useState(false);
    const [updateList, setUpdateList] = useState(false);

    const [showUpdateUserSurvey, setShowUpdateUserSurvey] = useState(false);
    const [updatingUserSurvey, setUpdatingUserSurvey] = useState<boolean>(false);
    const [payloadUpdateResponse, setPayloadUpdateResponse] = useState<UpdateUserSurvayPayload>({
        userId : patientId,
        responses: []
    })

    const { openIframe, setOpenIframe, setVisionContent } = useContext(VisioContentContext);
    const { userInfos } = useContext(UserContext);

    const handleClickNext = () => history.push(nextScreenPath);
    const handleClickPrev = () => {
        if (disablePrev) {
            return;
        } else {
            history.push(prevScreenPath);
        }
    };

    const { preSelectedPatientProgram, fetchingPreselectedProgram, fetchPreselectedProgram, userPrograms } =
        useFetchUserPrograms({
            userId: patientId,
        });

    const {
        fetchPatientDEResponses,
        userDEResponses,
        fetchingDEResponses,
        educationalDiagnosis,
        educationalDiagnosisVisio,
        fetching: fetchingeducationalDiagnosis,
        fetchSurveyByAxis,
        fetchEducationalDiagnosis,
    } = useEducationalDiagnosis({
        patientId,
        axisId,
    });

    useEffect(() => {
        fetchPreselectedProgram();
        fetchEducationalDiagnosis();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId, axisId]);

    useEffect(() => {
        // if (educationalDiagnosis?.id > 0) {

        if (preSelectedPatientProgram?.id) {
            fetchPatientDEResponses();
            // }
            fetchSurveyByAxis(axisId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId, axisId, educationalDiagnosis?.id, preSelectedPatientProgram?.id, updateList]);

    useEffect(() => {
        if (preSelectedPatientProgram?.id) {
            setPayload((prev) => ({
                ...prev,
                programId: preSelectedPatientProgram?.id,
                educationalDiagnosisId,
            }));
        }
    }, [preSelectedPatientProgram, educationalDiagnosisId]);

    const proposalAnswerSurveys = isDietetic ? userDEResponses?.[1]?.survey?.proposalAnswerSurveys : [];
    const groupedResponse = GroupeByKeyValue({ data: userDEResponses, key: 'surveyName' });
    const responseByGroupkey = (surveyName: string) => groupedResponse?.find((item) => item?.key === surveyName);
    const toggleVisio = () => {
        setOpenIframe(!openIframe);
        setVisionContent(educationalDiagnosisVisio);
    };
    const visioBtnLabel = openIframe ? 'Quitter la visio' : 'Démarrer la visio';
    const hasVisioLink = educationalDiagnosisVisio?.id > 0;
    const hasDEResponseInProgress = preSelectedPatientProgram?.id > 0 && educationalDiagnosis?.id > 0;
    const hasPrograms = userPrograms?.length > 0;
    const showEmptyMessage = educationalDiagnosis === null;
    const showPrevDEResponse = hasPrograms && !hasDEResponseInProgress;
    const lastProgram = userPrograms?.[userPrograms.length - 1];
    const programName =
        educationalDiagnosis?.id || preSelectedPatientProgram?.id
            ? preSelectedPatientProgram?.name ?? lastProgram?.program?.title
            : null;
    const userDEResponsesNoParent = userDEResponses.filter((item: any)=> item?.survey?.hasChild !== 1)
    const disableUpdateDe = fetchingPreselectedProgram || posting || fetchingeducationalDiagnosis || fetchingDEResponses || userDEResponses?.length === 0
    const userCurrentName = userInfos?.firstName + ' ' +userInfos?.lastName;
    const visioId = educationalDiagnosisVisio?.id;
    const visioRoomName = educationalDiagnosisVisio?.uniqid;

    const event: any =  educationalDiagnosis?.visioconferenceEvent;
    const eventStartDate = remoVeAdditionalTimeZone(event?.startDate);
    const calendarEndDate = remoVeAdditionalTimeZone(event?.endDate);
    const eventStartDateTime = new Date(eventStartDate);
    eventStartDateTime.setHours(0, 0, 0, 0);
    const eventEndDateTime = new Date(calendarEndDate);
    eventEndDateTime.setTime(eventEndDateTime.getTime() + 48 * 60 * 60 * 1000); // Ajoute 48 heures
    const currentTime = Date.now();
    const isCurrentDate = currentTime >= eventStartDateTime.getTime() && currentTime <= eventEndDateTime.getTime();

    const canValidate = educationalDiagnosis?.id && preSelectedPatientProgram?.id;

    return (
        <>
        <CustomVisioFramModal
                toggleModal={setOpenIframe}
                open={openIframe} 
                roomName={visioRoomName} 
                userName={userCurrentName}
                visioId={visioId} 
                recordEnabled={false}
        />

        <div className={classes.container}>
            <div className={classes.header}>
                <IconButton onClick={handleClickPrev}>
                    <KeyboardArrowLeft htmlColor={baseColors.white} fontSize="large" />
                </IconButton>

                <h4 className={classes.title}>Axe {title}</h4>

                <IconButton onClick={handleClickNext}>
                    <KeyboardArrowRight htmlColor={baseColors.white} fontSize="large" />
                </IconButton>
            </div>

            <div style={sxStyles.center}>
                <PatientProgram label="Programme : " value={programName ?? '(Aucun)'} />
            </div>

            <Spacer height={10} />

            <AxisLoaderContainer
                educationalDiagnosisId={educationalDiagnosisId}
                loading={fetchingeducationalDiagnosis || fetchingDEResponses}
            >
                {(showEmptyMessage && !showPrevDEResponse) || educationalDiagnosis === null ? (
                    <CustomText
                        style={{
                            textAlign: 'center',
                            marginTop: '10%',
                        }}
                        fontFamily={baseFontFamily.OpenSansSemiBold}
                        fontSize={baseFontSize.sl}
                    >
                        Le patient n'a pas encore répondu aux questions sur le Diagnostic Éducatif.
                    </CustomText>
                ) : userDEResponses?.length === 0 ? (
                    <CustomText
                        style={{
                            textAlign: 'center',
                            marginTop: '10%',
                        }}
                        fontFamily={baseFontFamily.OpenSansSemiBold}
                        fontSize={baseFontSize.sl}
                    >
                        Aucune question sur cet axe.
                    </CustomText>
                ) : (
                    <>
                        {!showUpdateUserSurvey && userDEResponsesNoParent?.map((content, i) =>
                            <DEQuestionResponses
                                    key={content.id}
                                    posting={posting}
                                    questionNumber={i + 1}
                                    content={content}
                                    setPayload={setPayload}
                                    setPosting={setPosting}
                                    canValidate={canValidate}
                                    isDietetic={isDietetic}
                                    proposalAnswerSurveys={proposalAnswerSurveys}
                                />
                        )}

                        {showUpdateUserSurvey && 
                        <EditResponseContainer 
                            axisId={axisId}
                            userDEResponses={userDEResponses}
                            payload={payloadUpdateResponse}
                            setPayload={setPayloadUpdateResponse}
                        />}

                        <Spacer />
                    </>
                )}
            </AxisLoaderContainer>

            {fetchingPreselectedProgram ? (
                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', padding: 10 }}>
                    <CustomCircleLoader height={10} width={25} />
                </div>
            ) : canValidate ? (
                <div style={{ ...sxStyles.footerContainer, justifyContent: hasVisioLink ? 'space-between' : 'center' }}>
                    {hasVisioLink && isCurrentDate && (
                        <Button
                            style={{
                                backgroundColor: openIframe ? baseColors.orange.primary : baseColors.black,
                                width: 300,
                                marginRight: 10,
                            }}
                            variant="contained"
                            endIcon={openIframe ? <CallEndIcon /> : <DuoIcon />}
                            onClick={toggleVisio}
                        >
                            <Typography
                                style={{
                                    color: baseColors.white,
                                    fontSize: 12,
                                    textTransform: 'uppercase',
                                    fontFamily: baseFontFamily.OpenSansBold,
                                }}
                            >
                                {visioBtnLabel}
                            </Typography>
                        </Button>
                    )}
                    {!showUpdateUserSurvey ?                   
                        <div style={{...sxStyles.center,gap:'7px'}}>
                            <CustomLoadingButton
                                disabled={disablePostObservationBtn({ payload, posting })}
                                loading={posting}
                                label="Valider"
                                handleClick={handlePostDEObservation({
                                    payload,
                                    setPayload,
                                    setPosting,
                                    setUpdateList,
                                })}
                            />

                            <CustomLoadingButton
                                disabled={disableUpdateDe}
                                label="Modifier les réponses du patient"
                                handleClick={()=> setShowUpdateUserSurvey(true)}
                            />
                        </div>
                        :
                        <div style={{...sxStyles.center, gap:'7px'}}>
                            <CustomLoadingButton
                                loading={updatingUserSurvey}
                                orange
                                label="Annuler"
                                handleClick={()=> setShowUpdateUserSurvey(false)}
                            />
                            <CustomLoadingButton
                                loading={updatingUserSurvey}
                                label="Modifier"
                                handleClick={() => performUpdateUserSurveys({
                                    payloadUpdateResponse,
                                    setUpdatingUserSurvey,
                                    setShowUpdateUserSurvey,
                                    setUpdateList
                                })}
                            />
                        </div>
                    }
                </div>
            ) : (
                <div style={sxStyles.footerContainer} />
            )}
        </div>
        </>
    );
};

export default AxisContainer;
