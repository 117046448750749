import React, { useState, FC } from 'react';
import { createContext } from 'react';

type SnackBarType = {
    showSnackBar: boolean;
    setShowSnackBar: (val: boolean) => void;
    snackBarMessage: string;
    setSnackBarMessage: (val: string) => void;
};

export const SnackBarContext = createContext<SnackBarType>({
    showSnackBar: false,
    setShowSnackBar: () => {},
    snackBarMessage: 'Opération réalisée avec succès',
    setSnackBarMessage: () => {},
});

const SnackBarProvider: FC<any> = (props) => {
    const { children } = props;
    const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
    const [snackBarMessage, setSnackBarMessage] = useState<string>('Opération réalisée avec succès');
 

    return (
        <SnackBarContext.Provider
            value={{
                showSnackBar,
                setShowSnackBar,
                snackBarMessage,
                setSnackBarMessage,
            }}
        >
            {children}
        </SnackBarContext.Provider>
    );
};

export default SnackBarProvider;
