import React, { useState, FC, useContext } from 'react';
import { createContext } from 'react';
import { LocalStorageService } from 'src/utils/storage';
import { initUser, UserContext } from './UserProvider';

type AuthType = {
    token: string | null;
    login: (token: string | null) => void;
    logout: () => void;
};

export const AuthContext = createContext<AuthType>({
    token: null,
    login: () => {},
    logout: () => {},
});

const AuthProvider: FC<any> = (props) => {
    const { children } = props;
    const [token, setToken] = useState<string | null>(null);
    const { setUserInfos } = useContext(UserContext);

    return (
        <AuthContext.Provider
            value={{
                token,
                login: (loginToken: string | null) => {
                    setToken(loginToken);
                },
                logout: () => {
                    setToken(null);
                    setUserInfos(initUser);
                    LocalStorageService.ClearStorage();
                },
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
