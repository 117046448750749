import React, { useState, FC, useEffect, useContext } from 'react';
import { createContext } from 'react';
import { User } from 'src/interfaces/interfaces';
import { URER_ROLES } from 'src/utils/constants/enums';
import { LocalStorageService } from 'src/utils/storage';
import { PlateformTypeContext } from './PlateformTypeProvider';

export const initUser = {
    id: 0,
    activated: 0,
    birthDate: '',
    category: '',
    email: '',
    firstName: '',
    lastLogin: '',
    lastName: '',
    phoneNumber: '',
    isExternal: 0,
    profilePicture: '',
    roles: [],
    specialityType: {
        id: 0,
        name: '',
        abbreviation: '',
    },
    practicePlace: '',
    rpps: '',
    hasEtp: 0,
};

type UserType = {
    userInfos: User;
    setUserInfos: (val: any) => void;
    isCoordinator?: boolean;
    isDoctor?: boolean;
    isAdmin?: boolean;
    userRole?: URER_ROLES.COORDINATOR | URER_ROLES.DOCTOR | URER_ROLES.ADMIN | '';
    hasEtp?: boolean;
    isExternal?: boolean;
    isETP: number;
    setIsETP: (val: number) => void;
    userCoActorStatus: UserCoActorStatus;
    setUserCoActorStatus: (val: UserCoActorStatus) => void;
    resetPasswordEmail?: string ,
    setResetPasswordEmail: (email:string) => void;
};

interface UserRole {
    RoleType: URER_ROLES.COORDINATOR | URER_ROLES.DOCTOR | URER_ROLES.ADMIN | '';
}
export interface UserCoActorStatus {
    isAcceptedByPatient: number;
    isAcceptedByProfessional: number;
}

export const userCoActorStatusDefault: UserCoActorStatus = {
    isAcceptedByPatient: 0,
    isAcceptedByProfessional: 0,
};

export const userCoActorStatusDefaultAccepted: UserCoActorStatus = {
    isAcceptedByPatient: 1,
    isAcceptedByProfessional: 1,
};

export const UserContext = createContext<UserType>({
    userInfos: initUser,
    setUserInfos: () => {},

    hasEtp: false,
    isExternal: true,
    isETP: 0,
    setIsETP: () => {},
    userCoActorStatus: userCoActorStatusDefault,
    setUserCoActorStatus: () => {},
    resetPasswordEmail: '',
    setResetPasswordEmail:() => {}
});

const UserProvider: FC<any> = (props) => {
    const { children } = props;
    const [userInfos, setUserInfos] = useState<User>(initUser);
    const [userCoActorStatus, setUserCoActorStatus] = useState<UserCoActorStatus>(userCoActorStatusDefault);
    const [userRole, setUserRole] = useState<UserRole['RoleType']>('');
    const [isCoordinator, setIsCoordinator] = useState<boolean>(false);
    const [isDoctor, setIsDoctor] = useState<boolean>(false);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [hasEtp, setHasEtp] = useState<boolean>(false);
    const [isETP, setIsETP] = useState<number>(0);
    const [resetPasswordEmail, setResetPasswordEmail] = useState<string>('');
    const { setIsCoordonatorFromPlateform } = useContext(PlateformTypeContext);

    const handleCheckUserRole = async (userInfos: User) => {
        let role: UserRole['RoleType'] = '';
        if (userInfos?.roles?.includes(URER_ROLES.COORDINATOR)) {
            setIsCoordonatorFromPlateform(true);
            setIsCoordinator(true);
            role = URER_ROLES.COORDINATOR;
        }
        if (userInfos?.roles?.includes(URER_ROLES.DOCTOR)) {
            setIsDoctor(true);
            role = URER_ROLES.DOCTOR;
        }
        if (userInfos?.roles?.includes(URER_ROLES.ADMIN)) {
            setIsAdmin(true);
            role = URER_ROLES.ADMIN;
        }
        setUserRole(role);
        LocalStorageService.SetUserRole(role);
    };

    useEffect(() => {
        setHasEtp(Boolean(userInfos?.hasEtp));
        handleCheckUserRole(userInfos);
    }, [userInfos]);

    return (
        <UserContext.Provider
            value={{
                userInfos,
                setUserInfos,

                isCoordinator,
                isDoctor,
                isAdmin,

                userRole,

                hasEtp,

                isETP,
                setIsETP,
                userCoActorStatus,
                setUserCoActorStatus,

                resetPasswordEmail,
                setResetPasswordEmail
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;
