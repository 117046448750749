import { Grid } from '@mui/material';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import CustomModal from 'src/components/CustomModal';
import { Workshop } from 'src/interfaces/interfaces';
import WorkshopService from 'src/services/Workshop/workshop.service';
import CustomCircleLoader from '../../../components/CustomLoader/CustomCircleLoader';
import { NameAndDuration } from './NameAndDuration';
import { toast } from 'react-toastify';
import { AddWorkshop, valideAddWorkshopForm,valideAddDraftWorkshopForm } from './Logic';
import { baseColors, baseScreenHeight } from 'src/utils/constants/baseStyles';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { WorkshopIntervenants } from './WorkshopIntervenants';
import EventRoomAndRemark from '../ConsultationForm/EventRoomAndRemark';
import ConsultationTypeForm from '../ConsultationForm/ConsultationTypeForm';
import { WorkshopRelatedGoals } from './WorkshopRelatedGoals';

interface WorkshopFormProps {
    forAdmin?: boolean;
    modalOpen: boolean;
    modalToggle: Function;
    dayHospitalId?: number;
    setUpdateList?: any;
    consultationData?: any;
    workshopData?: Workshop;
    forProgram?: boolean;
    programId?: number;
    customTitle?: string;
    setJustAddedWorkshop?: Dispatch<SetStateAction<any[]>>;
    callBack?: Function;
}

export const defaultWorkshopValue: Workshop = {
    name: '',
    duration: '',
    consultationTypeId: 1,
    remark: '',
    roomId: 0,
    attendees: [],
    goalsIds: [],
    dayHospitalId: null,
    axisId: 0,
    isDraft: 0
};

export const WorkshopForm: FC<WorkshopFormProps> = (props) => {
    const {
        programId,
        modalOpen,
        modalToggle,
        dayHospitalId,
        setUpdateList,
        consultationData,
        workshopData,
        forAdmin,
        forProgram,
        customTitle,
        setJustAddedWorkshop,
        callBack,
    } = props;
    const [savingData, setSavingData] = useState<boolean>(false);
    const [savingAsDraft, setSavingAsDraft] = useState<boolean>(false);
    const [lockWorkshopInput, setLockWorkshopInput] = useState<boolean>(false);
    const [payload, setPayload] = useState<Workshop>({
        ...defaultWorkshopValue,
        dayHospitalId: dayHospitalId ?? null,
    });
    const [attendeeTypeId, setAttendeeTypeId] = useState<number>(0);
    const isVisio = payload?.consultationTypeId === 2;
    const loadingData = <CustomCircleLoader height={25} width={25} />;

    const successfull = (message: string) => {
        setSavingData(false);
        setLockWorkshopInput(false);
        setSavingAsDraft(false);
        setUpdateList?.((prev: any) => !prev);
        setPayload(defaultWorkshopValue);
        modalToggle();
        toast.success(message);
        callBack?.()
    };

    const failed = (message: string) => {
        setSavingData(false);
        setLockWorkshopInput(false);
        setSavingAsDraft(false);
        toast.error(message);
    };

    const performWorkshop = (isDraft: number) => {
        isDraft ? setSavingAsDraft(true) : setSavingData(true);

        setLockWorkshopInput(true);
        const payloadDraft = { ...payload, isDraft: isDraft };
        if (workshopData && workshopData.id && workshopData?.id > 0) {
            WorkshopService.updateWorkshop(payloadDraft).then((res) => {
                if (res.data.code === 200) {
                    successfull('Atelier modifié avec succès');
                } else {
                    if (res.data.code === 400) {
                        failed('Le nom  existe déjà ');
                    }
                }
            });
        } else {
            const finalPayload = forProgram ? { ...payloadDraft, programId } : payloadDraft;
            WorkshopService.postWorkshop({ ...finalPayload }).then((res) => {
                if (res.data.code === 200) {
                    successfull('Atelier ajouté avec succès');
                    setJustAddedWorkshop?.((prev:any)=> ([...prev,res?.data?.data]))
                } else {
                    if (res.data.code === 400) {
                        failed("Le nom l'atelier existe déjà ");
                    }
                }
            });
        }
    };

    useEffect(() => {
        workshopData && forAdmin && setPayload(workshopData);
    }, [workshopData, forAdmin]);

    const toggleMainModal = () => {
        modalToggle(false);
        setPayload(defaultWorkshopValue);
    };

    const saving = savingAsDraft || savingData;
    const isNotValidated = workshopData?.isDraft || !workshopData?.id;
    const modalTitle = customTitle ? customTitle : (workshopData?.id! > 0 ? "Modifier l'atelier" : 'Nouvel atelier');
    const isAddFromGoal = Boolean(customTitle && customTitle?.length > 0);
    return (
        <CustomModal
            withMinHeight
            height={baseScreenHeight.newEventModal}
            width={600}
            title={modalTitle}
            toggleModal={toggleMainModal}
            open={modalOpen}
            footer={
                <Grid container flexDirection="row" justifyContent="space-between" alignItems="center">
                    {
                        (isNotValidated) && (
                            <CustomLoadingButton
                                style={{ backgroundColor: baseColors.orange.primary }}
                                loading={savingAsDraft}
                                disabled={!valideAddDraftWorkshopForm(payload as AddWorkshop) || saving}
                                handleClick={() => performWorkshop(1)}
                                width="49%"
                                label="Enregistrer comme brouillon"
                            />
                        )
                    }

                    <CustomLoadingButton
                        loading={savingData}
                        disabled={!valideAddWorkshopForm(payload as AddWorkshop) || saving}
                        handleClick={() => performWorkshop(0)}
                        width={isNotValidated ? "49%" : "99%"}
                        label="Valider l'atelier"
                    />
                </Grid>
            }
        >
            <NameAndDuration
                loadingData={loadingData}
                lockWorkshopInput={lockWorkshopInput}
                setPayload={setPayload}
                payload={payload}
                workshopData={workshopData}
            />

            <WorkshopIntervenants
                loadingData={loadingData}
                setAttendeeTypeId={setAttendeeTypeId}
                setPayload={setPayload}
                lockWorkshopInput={lockWorkshopInput}
                payload={payload}
                attendeeTypeId={attendeeTypeId}
                workshopData={workshopData}
            />

            
            {   !isAddFromGoal &&
                    <WorkshopRelatedGoals
                    loadingData={loadingData}
                    setPayload={setPayload}
                    lockWorkshopInput={lockWorkshopInput}
                    payload={payload}
                    workshopData={workshopData}
                    customTitle={customTitle}
                />
            }

            <ConsultationTypeForm
                loadingData={loadingData}
                setConsultation={setPayload}
                consultationData={consultationData}
                workshopData={workshopData}
            />

            <EventRoomAndRemark
                isVisio={isVisio}
                loadingData={loadingData}
                setConsultation={setPayload}
                consultationData={consultationData}
            />
        </CustomModal>
    );
};
