import { useState, FC, useContext } from 'react';
import { createContext } from 'react';
import { planningHours } from 'src/utils/constants/constants';
import { AgendaSettingsContext } from './AgendaSettingsProvider';
import moment from 'moment';
import { addZeroBeforeNumber } from 'src/utils/utils';
moment().format('L');

type AgendaType = {
    currentDate: Date;
    setCurrentDate: (val: Date) => void;

    hours: any;
    setHours: (val: any) => void;

    checkedPrograms: Array<number>;
    setCheckedPrograms: (val: any) => void;

    checkedEvents: Array<string>;
    setCheckedEvents: (val: any) => void;

    selectedEvent: any;
    setSelectedEvent: (val: any) => void;

    updateEventList: any;
    setUpdateEventList: (val: any) => void;

    checkedRooms: Array<any>;
    setCheckedRooms: (val: any) => void;

    allRooms: Array<any>;
    setAllRooms: (val: any) => void;

    checkedLocations: any;
    setCheckedLocations: (val: any) => void;

    allLocations: Array<any>;
    setAllLocations: (val: any) => void;

    eventData: any;
    setEventData: (val: any) => void;

    duration: Array<any>;
    setDuration: (val: any) => void;

    yearAndMonth: string;
    setYearAndMonth: (val: string) => void;

    agendaTimeSlot: number;
    setAgendaTimeSlot: (val: number) => void;

    calendarRef: any;
    setCalendarRef: (val: any) => void;

    calendarValue : any,
    setCalendarValue: (val: any) => void,
};

export const AgendaContext = createContext<AgendaType>({
    currentDate: new Date(),
    setCurrentDate: () => {},

    hours: null,
    setHours: () => {},

    checkedPrograms: [],
    setCheckedPrograms: () => {},

    checkedEvents: [],
    setCheckedEvents: () => {},

    selectedEvent: null,
    setSelectedEvent: () => {},

    updateEventList: false,
    setUpdateEventList: () => {},

    checkedRooms: [],
    setCheckedRooms: () => {},

    allRooms: [],
    setAllRooms: () => {},

    checkedLocations: null,
    setCheckedLocations: () => {},

    allLocations: [],
    setAllLocations: () => {},

    eventData: null,
    setEventData: () => {},

    duration: [],
    setDuration: () => {},

    yearAndMonth: '',
    setYearAndMonth: () => {},

    agendaTimeSlot: 60,
    setAgendaTimeSlot: () => {},

    calendarRef: null,
    setCalendarRef: () => {},

    calendarValue : null,
    setCalendarValue: () => {},
});

const AgendaProvider: FC<any> = (props) => {
    const { children } = props;
    const check = moment(new Date(), 'YYYY/MM/DD');
    const currentMonth = check.format('M');
    const currentYear = check.format('YYYY');
    const { time: defaultTime } = useContext(AgendaSettingsContext);
    const [currentDate, setCurrentDate] = useState<Date>(new Date());
    const [hours, setHours] = useState<any>(planningHours(defaultTime));
    const [selectedEvent, setSelectedEvent] = useState<any>(null);
    const [checkedPrograms, setCheckedPrograms] = useState<Array<number>>([]);
    const [duration, setDuration] = useState<Array<number>>([]);
    const [checkedEvents, setCheckedEvents] = useState<Array<string>>([]);
    const [updateEventList, setUpdateEventList] = useState<boolean>(false);
    const [checkedRooms, setCheckedRooms] = useState<Array<any>>([]);
    const [allRooms, setAllRooms] = useState<Array<string>>([]);
    const [checkedLocations, setCheckedLocations] = useState<any>(null);
    const [allLocations, setAllLocations] = useState<Array<string>>([]);
    const [eventData, setEventData] = useState<any>(null);
    const [yearAndMonth, setYearAndMonth] = useState<string>(`${currentYear}-${addZeroBeforeNumber(+currentMonth)}`);
    const [agendaTimeSlot, setAgendaTimeSlot] = useState<any>(null);
    const [calendarRef, setCalendarRef] = useState<any>(null);
    const [calendarValue, setCalendarValue] = useState<Date>(new Date());

    return (
        <AgendaContext.Provider
            value={{
                currentDate,
                setCurrentDate,

                hours,
                setHours,

                checkedPrograms,
                setCheckedPrograms,

                checkedEvents,
                setCheckedEvents,

                selectedEvent,
                setSelectedEvent,

                updateEventList,
                setUpdateEventList,

                checkedRooms,
                setCheckedRooms,

                allRooms,
                setAllRooms,

                checkedLocations,
                setCheckedLocations,

                allLocations,
                setAllLocations,

                eventData,
                setEventData,

                duration,
                setDuration,

                yearAndMonth,
                setYearAndMonth,

                agendaTimeSlot,
                setAgendaTimeSlot,

                calendarRef,
                setCalendarRef,

                calendarValue,
                setCalendarValue
            }}
        >
            {children}
        </AgendaContext.Provider>
    );
};

export default AgendaProvider;
