import FormControl from '@mui/material/FormControl';
import * as React from 'react';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { CustomTextEditor } from 'src/components/CustomEditor/CustomTextEditor';
import useNotesStyles from './NotesStyles';
import { toast } from 'react-toastify';
import { ContentState, EditorState, convertFromRaw } from 'draft-js';
import VisioconferenceService from 'src/services/Visioconference';
import { markdownToDraft } from 'markdown-draft-js';


interface NotesProps extends React.HTMLAttributes<HTMLDivElement> {
    visioId: number;
    readOnly?: boolean;
    value?: string;
}

const NotesPostMessage = {
    success: 'Mise à jour avec succès !',
    error: "Une erreur s'est produite lors de votre envoi.",
};

/**
 * @public
 */
export function Notes({
  visioId,
  readOnly,
  value,
  ...props
}: NotesProps) {

  const defaultNotes = {
    details: '',
  };
  const classes = useNotesStyles();
  const [posting, setPosting] = React.useState<boolean>(false);
  const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());
  const [notes, setNotes] = React.useState<any>(defaultNotes);

  async function handlePost() {
    const payload = {
        notes: notes.details
    };

    setPosting(true);
    await VisioconferenceService.putNotes(visioId, payload)
        .then((response) => {
            const data = response?.data?.data;
            const rawData = JSON.parse(data.notes);
            if(rawData?.raw){
                const contentState = convertFromRaw(rawData.raw);
                setEditorState(() => EditorState.createWithContent(contentState));
                setNotes({details: data.notes});
                toast.success(NotesPostMessage.success);
                setPosting(false);
            }
        })
        .catch((error) => {
            setPosting(false);
            console.error('Error post notes', error.message);
            toast.error(NotesPostMessage.error);
        });
    };

    React.useEffect(()=>{
        if(value){
            const rawData = JSON.parse(value);
            if(rawData?.raw){
                const contentState = convertFromRaw(rawData.raw);
                setEditorState(() => EditorState.createWithContent(contentState));
                setNotes({details: value})
            }
        }
    },[value])

  return (
    <div {...props} className={classes.container}>
      <div className={classes.header}>
        Notes
      </div>
      <div className={classes.content}>
        <FormControl>
            <CustomTextEditor
                handleChange={(value: any) => {setNotes((old: any) => ({ ...old, details: value }));}} 
                editorState={editorState} 
                setEditorState={setEditorState} 
                data={undefined}
                heightEditorStyle='50vh'
                maxHeightEditorStyle='70vh'
                readOnly={readOnly ?? false}
                isJsonData
            >
            </CustomTextEditor>
        </FormControl>
      </div>
      {
        !readOnly && 
        <div className={classes.footerBtn}>
            <CustomLoadingButton
                loading={posting}
                disabled={posting}
                label="Enregistrer"
                handleClick={handlePost}
            />
        </div>
      }
      
    </div>
  );
}
