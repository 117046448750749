import { useRoomContext } from '@livekit/components-react';
import { RadioButtonChecked, StopCircle } from '@mui/icons-material';
import { Room } from 'livekit-client';
import * as React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

type RecordState = {
    recording: boolean;
};

type EgressState = {
  egressId: string,
};
/**
 * @public
 */
export function RecordButton() {
    
    const [recordState, setRecordState] = React.useState<RecordState>({
        recording: false,
      });

    const [egressState, setEgressState] = React.useState<EgressState>({
        egressId: '',
    });

    const roomContext = useRoomContext();

    async function startRecord() {
        // Egress
        if (roomContext instanceof Room) {
  
            const properties = {
                roomName: roomContext.name,
                appEnv: process.env.REACT_APP_MODE,
                outputMode: 'COMPOSED'
            };
            const data = JSON.stringify(properties);

            const response = await axios.post(`${process.env.REACT_APP_IVEHTE_OPENVIDU_API}/recordings/start`, data, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });

            if (response.status == 200) {
                const roomCompositeEgress = response.data.info;

                setEgressState({
                    egressId: roomCompositeEgress.egressId
                });

                setRecordState({recording:true});
            } else {
                toast.error(response.data.message);
            }
        }
    }

    async function stopRecord() {
        if (egressState?.egressId) {
            const properties = {
                recordingId: egressState.egressId,
            };
            const data = JSON.stringify(properties);

            const response = await axios.post(`${process.env.REACT_APP_IVEHTE_OPENVIDU_API}/recordings/stop`, data, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });

            if (response.status == 200) {
                setRecordState({recording:false});
            } else {
                toast.error(response.data.message);
            }
        }
    }

    return (
        recordState.recording ? (
            <StopCircle style={{ cursor: 'pointer' }} onClick={stopRecord}></StopCircle>
        ) : (
            <RadioButtonChecked style={{ color: 'var(--lk-danger)', cursor: 'pointer' }} onClick={startRecord}></RadioButtonChecked>
        )
    );
}
