import { FC, useContext, useEffect, useState } from 'react';
import { Switch, Route, useRouteMatch, useHistory, useParams } from 'react-router-dom';
import { ProgramDetails } from './ProgramDetails/ProgramDetails';
import { ProgramSynthesis } from './ProgramSynthesis';
import { ProgramEmpty } from './ProgramEmpty';
import { Box } from '@mui/material';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import AddProgram from './AddProgam';
import useDebounce from 'src/hooks/useDebounce';
import { ReportForm } from './../EducatifDiagnostic/Report/ReportForm';
import { UserContext } from 'src/providers/UserProvider';
import { useFetchUserPrograms } from 'src/hooks/useFetchUserPrograms';
import AddedToNewProgram from './AddProgam/AddedToNewProgram';
interface ProgramsProps {
    patientInfo?: any;
    defaultPatient?: any;
    isActor?: any;
}

export const Programs: FC<ProgramsProps> = (props) => {
    const { patientInfo, defaultPatient, isActor } = props;
    const { path, url } = useRouteMatch();
    const params = useParams<any>();
    const history = useHistory();
    const { isDoctor } = useContext(UserContext);
    const [update, setUpdate] = useState<boolean>(false);
    const [hideEmptyCmpt, setHideEmptyCmpt] = useState<boolean>(false);
    const [patientDefaultStatus, setPatientDefaultStatus] = useState();
    const userId: number = +params?.id || 0;

    const gotoProgramDetail = () => history.push(url + '/details');

    const { fetchUserPrograms, fetchingUserPrograms, userPrograms } = useFetchUserPrograms({
        userId,
    });

    const { debouncedResults: debouncedDataFetching } = useDebounce(fetchUserPrograms);

    useEffect(() => {
        if (isDoctor) {
            if (isActor?.length === 0) {
                setPatientDefaultStatus(defaultPatient);
            } else {
                setPatientDefaultStatus(isActor?.isAcceptedByProfessional);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientInfo?.id]);

    useEffect(() => {
        debouncedDataFetching(+userId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, update]);

    const isEmpty = userPrograms?.length === 0;

    useEffect(() => {
        !isEmpty && gotoProgramDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEmpty]);

    return (
        <Box
            className="programs-box"
            style={{
                padding: 10,
                flexGrow: 1,
                overflowY: 'scroll',
                background: 'white',
                height: 'calc(100vh - 280px)',
            }}
        >
            {fetchingUserPrograms ? (
                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 110 }}>
                    <CustomCircleLoader height={70} width={70} />
                </div>
            ) : (
                isEmpty && !hideEmptyCmpt && <ProgramEmpty />
            )}

            {!fetchingUserPrograms && (
                <Switch>
                    <Route path={`${path}/add`}>
                        <AddProgram setHideEmptyCmpt={setHideEmptyCmpt} setUpdate={setUpdate} />
                    </Route>
                    <Route path={`${path}/addedToNewProgram`}>
                        <AddedToNewProgram />
                    </Route>
                    <Route path={`${path}/details`}>{userPrograms.length > 0 && <ProgramDetails />}</Route>
                    <Route path={`${path}/syntesis`}>
                        <ProgramSynthesis isActor={patientDefaultStatus} />
                    </Route>
                    <Route path={`${path}/report`}>
                        <ReportForm />
                    </Route>
                </Switch>
            )}
        </Box>
    );
};
