import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { baseColors, baseFontFamily, baseFontSize, baseFontWeight } from 'src/utils/constants/baseStyles';
import { Box } from '@mui/material';
import { CustomText } from 'src/components/Text/CustomText';
import { fullName, formatDateDiag, remoVeAdditionalTimeZone } from 'src/utils/utils';
import { Spacer } from 'src/components/Spacer/Spacer';
import { IconButton } from '@mui/material';
import VideoChatIcon from '@mui/icons-material/VideoChat';
import { CustomConfirm } from 'src/components/CustomConfirm/CustomConfirm';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

interface DiagnosticModalEventDetailProps {
    patientName: string;
    eventToCheck: any;
    isVisio: boolean;
    disableVisio: boolean | undefined;
    isCurrentDate: boolean;
    intervenants: any;
    hasNoIntervenants: boolean;
    deleting: boolean;
    setSeeNote?: Dispatch<SetStateAction<boolean>>;
    toggleIframModal: any;
    disableBtn: boolean | undefined;
    handleNavigateToPatientDetails: any;
    openDeleteEventModal: any;
    setOpenDeleteEventModal: Dispatch<SetStateAction<boolean>>;
    handleDeleteEvent: Function
}

const DiagnosticModalEventDetail: FC<DiagnosticModalEventDetailProps> = (props) => {
    const {
        patientName,
        eventToCheck,
        isVisio,
        disableVisio,
        isCurrentDate,
        deleting,
        setSeeNote,
        hasNoIntervenants,
        disableBtn,
        intervenants,
        toggleIframModal,
        handleNavigateToPatientDetails,
        openDeleteEventModal,
        setOpenDeleteEventModal,
        handleDeleteEvent
    } = props;

    const handleShowNote = () => {
        setSeeNote?.(true)
    }
    return (
        <>
            <Box style={{ marginTop: 30 }}>
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        cursor: patientName && 'pointer',
                        marginBottom: 10,
                    }}
                    onClick={patientName ? handleNavigateToPatientDetails : undefined}
                >
                    <CustomText
                        style={{
                            fontSize: baseFontSize.sm,
                        }}
                    >
                        Nom du patient:
                    </CustomText>

                    <CustomText
                        style={{
                            textDecorationLine: patientName && 'underline',
                            fontSize: baseFontSize.md,
                            color: patientName && baseColors.orange.primary,
                            fontWeight: patientName && baseFontWeight.bold,
                            marginLeft: 10,
                        }}
                    >
                        {patientName || '(Non renseigné)'}
                    </CustomText>
                </Box>

                <Spacer />

                <CustomText
                    style={{
                        fontSize: baseFontSize.sm,
                    }}
                >
                    Type d'événement: {eventToCheck?.eventType?.name}
                </CustomText>

                <Spacer />

                <CustomText
                    style={{
                        fontSize: baseFontSize.sm,
                    }}
                >
                    Programme: {eventToCheck?.program?.title ?? '(Non renseigné)'}
                </CustomText>

                <Spacer />

                <CustomText
                    style={{
                        fontSize: baseFontSize.sm,
                    }}
                >
                    Date: {formatDateDiag(remoVeAdditionalTimeZone(eventToCheck?.startDate))}
                </CustomText>

                <Spacer />

                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <CustomText
                        style={{
                            fontSize: baseFontSize.sm,
                        }}
                    >
                        {isVisio ? 'Participer au rendez-vous à distance' : 'Lieu'}:
                    </CustomText>

                    <div style={{ cursor: disableVisio ? 'not-allowed' : 'pointer', opacity: !isCurrentDate ? 0.5 : 1 }}>
                        {isVisio ? (
                            <IconButton disabled={disableVisio} aria-label="Démarer l'atelier en direct" onClick={toggleIframModal}>
                                <VideoChatIcon sx={{ fontSize: 35, color: baseColors.orange.primary }} />
                            </IconButton>
                        ) : (
                            <CustomText
                                style={{
                                    fontSize: baseFontSize.sm,
                                    marginLeft: 10,
                                }}
                            >
                                {eventToCheck?.customLocation ?? ''}
                            </CustomText>
                        )}
                    </div>
                        { isVisio && 

                            <CustomText
                                style={{ minWidth: '250px', cursor: 'pointer', marginLeft: '15px' }}
                                fontFamily={baseFontFamily.OpenSansSemiBold}
                                fontSize={15}
                                color={baseColors.green.primary}
                            >
                                <span onClick={handleShowNote}>
                                    Voir les notes
                                </span>
                            </CustomText>
                        }
                </Box>

                <Spacer />

                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <CustomText
                        style={{
                            fontSize: baseFontSize.sm,
                        }}
                    >
                        Intervenants :
                    </CustomText>

                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            marginLeft: 10,
                        }}
                    >
                        {hasNoIntervenants ? (
                            <CustomText
                                style={{
                                    fontSize: baseFontSize.sm,
                                }}
                            >
                                (Non renseigné)
                            </CustomText>
                        ) : (
                            intervenants?.map((intervenant: any, i: any) => (
                                <>
                                    <CustomText
                                        style={{
                                            fontSize: baseFontSize.sm,
                                            paddingLeft: '5px',
                                        }}
                                    >
                                        {fullName(
                                            intervenant?.attendeeUser?.firstName,
                                            intervenant?.attendeeUser?.lastName,
                                        )}
                                    </CustomText>
                                    {i !== Object.keys(intervenants).length - 1 ? ',' : ''}
                                </>
                            ))
                        )}
                    </Box>
                </Box>
            </Box>

            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: 30,
                    marginBottom: 10,
                }}
            >
                <CustomConfirm
                    title="Supprimer l'événement"
                    defaultLoading={deleting}
                    yesLabel="Supprimer"
                    noLabel="ANNULER"
                    modalVisible={openDeleteEventModal}
                    updateModalVisible={setOpenDeleteEventModal}
                    message={
                        deleting ? 'Suppression en cours...' : "Voulez-vous vraiment supprimer l'événement ?"
                    }
                    callBackconfirm={handleDeleteEvent}
                    disable={disableBtn}
                />
            </Box>
        </>
    );
};

export default DiagnosticModalEventDetail;
