import FormControl from '@mui/material/FormControl';
import * as React from 'react';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { CustomTextEditor } from 'src/components/CustomEditor/CustomTextEditor';
import { toast } from 'react-toastify';
import { ContentState, EditorState, convertFromRaw } from 'draft-js';
import VisioconferenceService from 'src/services/Visioconference';
import { markdownToDraft } from 'markdown-draft-js';
import { useNotesStyles } from './styles';
import { Box } from '@mui/material';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { ucfirst } from 'src/utils/utils';
import { SetMediaDeviceOptions } from '@livekit/components-core';


interface NotesProps extends React.HTMLAttributes<HTMLDivElement> {
    visioId: number;
    readOnly?: boolean;
    setEditNote?: React.Dispatch<React.SetStateAction<boolean>>;
    editMode: boolean;
    setSeeNote?: React.Dispatch<React.SetStateAction<boolean>>;
    value?: string;
    setValue?: React.Dispatch<React.SetStateAction<string>>;
    eventDate:  string
}

const NotesPostMessage = {
    success: 'Mise à jour avec succès !',
    error: "Une erreur s'est produite lors de votre envoi.",
};

/**
 * @public
 */
export function DiagnosticModalNote({
  visioId,
  readOnly,
  setSeeNote,
  editMode,
  setEditNote,
  value,
  setValue,
  eventDate,
  ...props
}: NotesProps) {

  const defaultNotes = {
    details: '',
  };
  const classes = useNotesStyles({readOnly});
  const [posting, setPosting] = React.useState<boolean>(false);
  const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());
  const [notes, setNotes] = React.useState<any>(defaultNotes);

  async function handlePost() {
    const payload = {
        notes: notes.details
    };

    setPosting(true);
    await VisioconferenceService.putNotes(visioId, payload)
        .then((response) => {
            const data = response?.data?.data;
            setEditorState(() => EditorState.createWithContent(ContentState.createFromText(data?.notes)));
            toast.success(NotesPostMessage.success);
            setPosting(false);
            setEditNote?.(false);
            setSeeNote?.(true);
            setValue?.(data?.notes);
        })
        .catch((error) => {
            setPosting(false);
            console.error('Error post notes', error.message);
            toast.error(NotesPostMessage.error);
        });
    };

    const handleCancel = () => {
        setEditNote?.(false);
        setSeeNote?.(true);
    }

    React.useEffect(()=>{
        if(value){
            const rawData = JSON.parse(value);
            if(rawData?.raw){
                const contentState = convertFromRaw(rawData.raw);
                setEditorState(() => EditorState.createWithContent(contentState));
                setNotes({details: value})
            }
        }
    },[value])

  return (
    <div {...props} className={classes.container}>
      <div className={classes.content}>
        <CustomText
            style={{ minWidth: '250px', margin: '0 auto' }}
            fontFamily={baseFontFamily.OpenSansSemiBold}
            fontSize={15}
            color={baseColors.black}
        >
            {ucfirst(eventDate)}
        </CustomText>
        <CustomTextEditor
            handleChange={(value: any) => {setNotes((old: any) => ({ ...old, details: value }));}} 
            editorState={editorState} 
            setEditorState={setEditorState} 
            data={undefined}
            heightEditorStyle='50vh'
            maxHeightEditorStyle='70vh'
            readOnly={readOnly ?? false}
            isJsonData
        >
        </CustomTextEditor>
      </div>
      {
        editMode && 
        <div className={classes.footerBtn}>
            <CustomLoadingButton
                orange
                label="Annuler"
                handleClick={handleCancel}
            />

            <CustomLoadingButton
                loading={posting}
                disabled={posting}
                label="Enregistrer"
                handleClick={handlePost}
            />
        </div>
      }
      
    </div>
  );
}
