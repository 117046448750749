import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { DeleteRequestResponse, Workshop, WorkshopItem } from 'src/interfaces/interfaces';
import { WorkshopContext } from 'src/providers/WorkshopProvider';
import WorkshopService from 'src/services/Workshop/workshop.service';
import { searchElementByName, sortByIds } from 'src/utils/utils';
import { defaultWorkshopValue } from 'src/views/WorkshopForm/WorkshopForm/WorkshopForm';

interface IProps {
    toggleModal?: Function;
    setPayload?: Dispatch<SetStateAction<Workshop>>;
    setUpdateList?: Dispatch<SetStateAction<boolean>>;
    setModalConfirmVisible?: Dispatch<SetStateAction<boolean>>;
    setWorkshop?: Dispatch<SetStateAction<any>>;
}

const useWorkshops = (props:IProps) => {
    const {
        toggleModal,
        setPayload,
        setUpdateList,
        setModalConfirmVisible,
        setWorkshop,
    } = props;

    const [fetchingWorkshops, setFetchingWorkshops] = useState<boolean>(true);
    const [workshops, setWorkshops] = useState<any>([]);
    const [optimizedWorkshops, setOptimizedWorkshops] = useState<any>([]);
    const [searchedWorkshops, setSearchedWorkshops] = useState<Array<any>>([]);
    const [workshopSearchkey, setWorkshopSearchkey] = useState<string>('');
    const { setWorkshops: setWorkshopsInContext } = useContext(WorkshopContext);
    const [loading, setLoading] = useState<boolean>(false);

    const successfull = (message: string, response?: DeleteRequestResponse) => {
        if (response?.code === 409) {
            toast.error("Impossible de supprimer la compétence car c'est déjà liée à d'autre donnée");
        } else if (response?.code === 400) {
            toast.error('Compétence existe déjà');
        } else {
            setModalConfirmVisible?.((prev) => !prev);
            setUpdateList?.((prev) => !prev);
            toggleModal?.();
            setWorkshop?.({} as WorkshopItem);
            toast.success(message, { style: { zIndex: 150 } });
            setPayload?.(defaultWorkshopValue);
        }
        setLoading?.(false);
    };

    const failed = (message: string) => {
        toast.error(message);
        setLoading?.(false);
    };

    const handleFetchWorkshops = () => {
        setFetchingWorkshops(true);

        WorkshopService.getWorkshops({})
            .then((response) => {
                const data = response?.data?.data;
                const removeDraftWorkshops = data?.filter((item: any) => !item.isDraft);
                const optimized = Array.from(removeDraftWorkshops, (item: { id: number; name: string }) => ({
                    id: item.id,
                    name: item.name,
                }));

                if (data?.length > 0) {
                    const sortedData = sortByIds(removeDraftWorkshops ?? []);
                    setWorkshops(sortedData);
                    setWorkshopsInContext(sortedData);
                    setOptimizedWorkshops(sortByIds(optimized ?? []));
                }
                setFetchingWorkshops(false);
            })
            .catch((e) => {
                setFetchingWorkshops(false);
                toast.error('Une erreur est survenue lors de la récupération des ateliers');
            });
    };
    
    const handleDeleteWorkshop = (workshopId : number) => {
        setLoading?.(true);
        WorkshopService.deleteWorkshop(workshopId)
        .then((response) => {
            const statusCode = response?.data?.code;
            if (statusCode === 409) {
                failed('Vous ne pouvez pas supprimer un atelier lié à un programme');
            } else {
                successfull('Suppression de l\'atelier réussie !', response?.data);
            }
        })
        .catch((e) => {
            console.log(e);
            setLoading?.(false);
            setModalConfirmVisible?.((prev: any) => !prev);
        });
    };


    useEffect(() => {
        setSearchedWorkshops(searchElementByName(optimizedWorkshops ?? [], workshopSearchkey, 'name'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workshopSearchkey]);

    const finalWorkshopsData = workshopSearchkey?.trim()?.length > 0 ? searchedWorkshops : workshops;

    return {
        fetchingWorkshops,
        workshops,
        optimizedWorkshops,
        handleFetchWorkshops,

        searchedWorkshops,
        setWorkshopSearchkey,
        finalWorkshopsData,

        handleDeleteWorkshop,
        loading
    };
};

export default useWorkshops;
