import { FC, useContext, useEffect, useState } from 'react';
import CustomModal from '../CustomModal';
import { baseColors, baseFontFamily, baseFontSize, baseFontWeight } from 'src/utils/constants/baseStyles';
import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import EventService from 'src/services/Calendar/events.service';
import EventModal from 'src/views/MyAgenda/EventModal';
import { EventPlanningViewType } from 'src/interfaces/interfaces';
import { AgendaContext } from 'src/providers/AgendaProvider';
import { toast } from 'react-toastify';
import { OperationMessage } from 'src/utils/constants/constants';
import { UserContext } from 'src/providers/UserProvider';
import { CustomText } from 'src/components/Text/CustomText';
import { fullName, formatDateDiag, remoVeAdditionalTimeZone, ucfirst } from 'src/utils/utils';
import { Spacer } from 'src/components/Spacer/Spacer';
import { IconButton } from '@mui/material';
import VideoChatIcon from '@mui/icons-material/VideoChat';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { PatientContext } from 'src/providers/PatientProvider';
import { CustomConfirm } from 'src/components/CustomConfirm/CustomConfirm';
import { isConnectedUserIntervenant } from 'src/views/MyAgenda/Planning/EventUtils';
import CustomVisioFramModal from '../CustomVisioFramModal';
import DiagnosticModalEventDetail from './DiagnosticModalEventDetail';
import { Notes } from 'src/components/CustomVisioVideo/dist/Notes';
import { DiagnosticModalNote } from './DiagnosticModalNote';
import MarkdownRenderer from 'src/components/InputField/MarkdownRenderer';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

interface DiagnosticModalProps {
    toggleModal: any;
    open: boolean;
    data?: any;
    updateEventList?: any;
    dataForWeeklyEvent?: any;
    viewType?: EventPlanningViewType['viewType'];
    readOnly?: boolean;
    eventId?: number;
    isExternalDoctor?: boolean;
    isCoordinator?: boolean;
}

const DiagnosticModal: FC<DiagnosticModalProps> = (props) => {
    const {
        open: defaultOpen,
        eventId,
        toggleModal,
        data,
        updateEventList,
        dataForWeeklyEvent,
        viewType,
        readOnly,
        isExternalDoctor,
        isCoordinator,
    } = props;
    const { userInfos } = useContext(UserContext);
    const history = useHistory();
    const [open, setOpen] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<boolean>(false);
    const [loadingEvent, setLoadingEvent] = useState<boolean>(false);
    const [openDeleteEventModal, setOpenDeleteEventModal] = useState<boolean>(false);
    const toggleEditEventModal = () => setOpen(!open);
    const toggleRemoveEventModal = () => setOpenDeleteEventModal(!openDeleteEventModal);
    const { eventData, setEventData } = useContext(AgendaContext);
    const { setCurrentPatientId } = useContext(PatientContext);
    const patientId = data?.patientId ? data?.patientId : eventData?.patient?.attendeeUser?.id;

    const [seeNote, setSeeNote] = useState<boolean>(false);
    const [editNote, setEditNote] = useState<boolean>(false);
    const [notes, setNotes] = useState<string>('');

    const handleNavigateToPatientDetails = () => {
        setCurrentPatientId(patientId);
        const patientDetailsUrl = `/patients/list/${patientId}/profile`;
        history.push(patientDetailsUrl);
    };

    const [openIframModal, setOpenIframModal] = useState<boolean>(false);
    const [creatingLink, setCreatingLink] = useState<boolean>(false);

    const selectedEventId: number = eventId || 0;

    const handleFetchData = () => {
        setLoadingEvent(true);
        EventService.getEvent(selectedEventId)
            .then((res: any) => {
                const response = res?.data?.data;
                setEventData(response);
                setLoadingEvent(false);
            })
            .catch(() => setLoadingEvent(false));
    };

    const event = eventData ?? data;

    useEffect(() => {
        if (selectedEventId > 0) {
            handleFetchData();
        }
    }, [open, selectedEventId, openIframModal]);

    useEffect(()=>{
        if(!defaultOpen){
            setSeeNote(false);
            setEditNote(false);
        }
    },[defaultOpen])

    useEffect(()=>{
        if(event?.visioconference?.notes){
            setNotes(event?.visioconference?.notes)
        }
    },[event])

    const toggleIframModal = () => {
        setCreatingLink(true);
        setOpenIframModal(!openIframModal);

        setTimeout(() => {
            setCreatingLink(false);
        }, 5000);
    };

    const handleDeleteEvent = () => {
        setDeleting(true);
        EventService.deleteEvent(data?.eventId ?? data?.id)
            .then((res: any) => {
                toast.success(OperationMessage.success);
                setDeleting(false);
                updateEventList((prev: any) => !prev);
                toggleRemoveEventModal();
                toggleModal();
            })
            .catch(() => {
                setDeleting(false);
                toast.error(OperationMessage.error);
            });
    };

    const switchToNoteModeEdit = () => {
        setEditNote(true);
        setSeeNote(false);
    }

    const backToEventDetail = () => {
        setEditNote(false);
        setSeeNote(false);
    }
    
    const eventToCheck = selectedEventId > 0 ? eventData : event;

    const patientName = fullName(
        eventToCheck?.patient?.attendeeUser?.firstName,
        eventToCheck?.patient?.attendeeUser?.lastName,
    );
    const eventTitle = eventToCheck?.summary;
    const isVisio = eventToCheck?.consultationType?.code === 'VISIO';

    const intervenants = eventToCheck?.attendees?.filter((intervenant: any) => intervenant.attendeeType.id === 2);
    const hasNoIntervenants = intervenants?.length === 0;

    const eventToUse = eventData?.id > 0 ? eventData : event;

    const isMyEvent =
        eventData?.ownerId === userInfos?.id ||
        eventData?.organizerId === userInfos?.id ||
        isCoordinator ||
        isConnectedUserIntervenant({ event: eventToUse, myId: userInfos?.id ?? 0 });
    const disableBtn = loadingEvent || readOnly || deleting || !isMyEvent || isExternalDoctor;

    const calendarStartDate = remoVeAdditionalTimeZone(event?.startDate);
    const calendarStartDateTime = new Date(calendarStartDate);
    calendarStartDateTime.setHours(0, 0, 0, 0); // Définit l'heure de début à minuit (00:00)

    const calendarEndDate = remoVeAdditionalTimeZone(event?.endDate);
    const calendarEndDateTime = new Date(calendarEndDate);
    calendarEndDateTime.setTime(calendarEndDateTime.getTime() + 48 * 60 * 60 * 1000); // Ajoute 48 heures


    const currentTime = Date.now();
    const isCurrentDate = currentTime >= calendarStartDateTime.getTime() && currentTime <= calendarEndDateTime.getTime();
    
    const visioId = event?.visioconference?.id;
    const visioRoomName = event?.visioconference?.uniqid;
    const userCurrentName = userInfos?.firstName + ' ' +userInfos?.lastName;
    const disableVisio = !isCurrentDate  || readOnly || isExternalDoctor;
    const humanDate = formatDateDiag(remoVeAdditionalTimeZone(eventToCheck?.startDate))

    return (
        <CustomModal
            open={defaultOpen}
            toggleModal={toggleModal}
            title={"Événement patient"}
            width={"100%"}
            withMinHeight
            footer={
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {
                        seeNote  ? 
                        <>
                            <CustomLoadingButton
                                orange
                                disabled={disableBtn}
                                handleClick={backToEventDetail}
                                loading={deleting}
                                marginRight={10}
                                label="Retour"
                            />

                            <CustomLoadingButton
                                disabled={disableBtn}
                                handleClick={switchToNoteModeEdit}
                                loading={false}
                                marginLeft={10}
                                icon={<EditOutlinedIcon
                                    sx={{
                                        fontSize: 'medium',
                                        color: baseColors.white,
                                        cursor: 'pointer',
                                    }}
                                />}
                                label="Modifier la note"
                            />
                        </> 
                        :
                        !editNote &&
                        <>
                            <CustomLoadingButton
                                orange
                                disabled={disableBtn}
                                handleClick={toggleRemoveEventModal}
                                loading={deleting}
                                marginRight={10}
                                label="Supprimer"
                            />

                            <CustomLoadingButton
                                disabled={disableBtn}
                                handleClick={toggleEditEventModal}
                                loading={false}
                                marginLeft={10}
                                label="Modifier"
                            />
                        </>
                    }
                    
                </div>
            }
        >
            {loadingEvent ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '50px',
                        minWidth: '310px',
                        width: '100%'
                    }}
                >
                    <CustomCircleLoader />
                </div>
            ) : 
                seeNote ? 
                <DiagnosticModalNote
                    visioId={visioId}
                    value={notes}
                    setValue={setNotes}
                    readOnly={true}
                    setSeeNote={setSeeNote}
                    setEditNote={setEditNote}
                    editMode={editNote}
                    eventDate={`${eventTitle}, ${humanDate}`}
                />
                : 
                editNote ?
                <DiagnosticModalNote
                    visioId={visioId} 
                    value={notes}
                    setValue={setNotes}
                    readOnly={false}
                    setSeeNote={setSeeNote}
                    setEditNote={setEditNote}
                    editMode={editNote}
                    eventDate={`${eventTitle}, ${humanDate}`}
                />:
                <DiagnosticModalEventDetail
                    patientName={patientName}
                    eventToCheck={eventToCheck}
                    isVisio={isVisio}
                    disableVisio={disableVisio}
                    isCurrentDate={isCurrentDate}
                    deleting={deleting}
                    hasNoIntervenants={hasNoIntervenants}
                    disableBtn={disableBtn}
                    intervenants={intervenants}
                    toggleIframModal={toggleIframModal}
                    handleNavigateToPatientDetails={handleNavigateToPatientDetails}
                    openDeleteEventModal={openDeleteEventModal}
                    setOpenDeleteEventModal={setOpenDeleteEventModal}
                    handleDeleteEvent={handleDeleteEvent}
                    setSeeNote={setSeeNote}
                />

            }

            <CustomVisioFramModal
                toggleModal={toggleIframModal} 
                open={openIframModal} 
                roomName={visioRoomName} 
                userName={userCurrentName} 
                visioId={visioId} 
                recordEnabled={false}
            />

            <EventModal
                setUpdateList={updateEventList}
                modalOpen={open}
                modalToggle={toggleEditEventModal}
                eventData={eventToUse}
                toggleEventModal={toggleModal}
                dataForWeeklyEvent={dataForWeeklyEvent}
                viewType={viewType}
                edition
            />
        </CustomModal>
    );
};

export default DiagnosticModal;
