import { useState, FC } from 'react';
import { createContext } from 'react';

type MenuAuthorizationType = {
    fetchingAuthorizedMenus: boolean;
    setFetchingAuthorizedMenus: (val: boolean) => void;

    authorizedMenus: string[];
    setAuthorizedMenus: (val: any) => void;

    authorizedSubMenus: string[];
    setAuthorizedSubMenus: (val: any) => void;
};

export const MenuAuthorizationContext = createContext<MenuAuthorizationType>({
    fetchingAuthorizedMenus: false,
    setFetchingAuthorizedMenus: () => {},

    authorizedMenus: [],
    setAuthorizedMenus: () => {},

    authorizedSubMenus: [],
    setAuthorizedSubMenus: () => {},
});

const MenuAuthorizationProvider: FC<any> = (props) => {
    const { children } = props;
    const [authorizedMenus, setAuthorizedMenus] = useState<string[]>([]);
    const [authorizedSubMenus, setAuthorizedSubMenus] = useState<string[]>([]);
    const [fetchingAuthorizedMenus, setFetchingAuthorizedMenus] = useState<boolean>(false);

    return (
        <MenuAuthorizationContext.Provider
            value={{
                fetchingAuthorizedMenus,
                setFetchingAuthorizedMenus,

                authorizedMenus,
                setAuthorizedMenus,

                authorizedSubMenus,
                setAuthorizedSubMenus,
            }}
        >
            {children}
        </MenuAuthorizationContext.Provider>
    );
};

export default MenuAuthorizationProvider;
