import { makeStyles } from '@mui/styles';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';

const useNotesStyles = makeStyles(() => ({
    container: {
      alignItems: 'end',
      backgroundColor: '#1e1e1e',
      borderLeft: '1px solid #ffffff1a',
      display: 'block',
      width: 'auto',
    },
    header: {
      alignItems: 'center',
      display: 'flex',
      height: '20px',
      justifyContent: 'center',
      padding: '.75rem',
      position: 'relative',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        marginTop: '-40px',
        width: '88%',
        height: 'auto',
        color: '#000000',
        padding: '10px',
        editorStyle: '50vh',
    },
    footerBtn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '15px',
        marginBottom: '15px',
        height: '20px',
    },
}));


export default useNotesStyles;