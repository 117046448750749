import { CSSProperties, FC } from 'react';
import { Typography } from '@mui/material';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import LoadingButton from '@mui/lab/LoadingButton';
import { specialCharToUnderscore } from 'src/utils/utils';

interface CustomLoadingButtonProps {
    label: string;
    width?: number | string;
    handleClick: Function;
    size?: any;
    loading?: boolean;
    disabled?: boolean;
    outlined?: boolean;
    marginRight?: number;
    marginLeft?: number;
    orange?: boolean;
    visible?: any;
    style?: CSSProperties;
    icon?: JSX.Element;
}

const CustomLoadingButton: FC<CustomLoadingButtonProps> = (props) => {
    const { label, width, handleClick, loading, disabled, outlined, marginRight, marginLeft, orange, visible, style, icon } =
        props;

    return (
        <LoadingButton
            loading={loading}
            loadingPosition="center"
            disabled={disabled}
            onClick={() => handleClick()}
            style={{
                visibility: visible,
                backgroundColor: outlined
                    ? 'transparent'
                    : orange
                    ? baseColors.orange.primary
                    : baseColors.green.primary,
                width: width ?? 300,
                justifyContent: 'center',
                borderColor: orange ? 'transparent' : baseColors.green.primary,
                alignItems: 'center',
                marginRight: marginRight ?? undefined,
                marginLeft: marginLeft ?? undefined,
                ...style,
            }}
            size={'large'}
            variant={outlined ? 'outlined' : 'contained'}
            id={specialCharToUnderscore(label ?? '')}
        >
            {icon && <div style={{marginRight: '15px',display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{icon}</div>}
            <Typography
                style={{
                    color: outlined ? baseColors.green.primary : loading ? 'transparent' : baseColors.white,
                    fontSize: 12,
                    textTransform: 'uppercase',
                    fontFamily: baseFontFamily.OpenSansBold,
                    opacity: disabled ? 0.5 : 1,
                }}
            >
                {label}
            </Typography>
        </LoadingButton>
    );
};

export default CustomLoadingButton;
