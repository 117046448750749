import { Component } from "react";
import "./VideoUserRoom.css";
import axios from "axios";
import { DisconnectButton, LeaveIcon, LiveKitRoom } from "@livekit/components-react";
import '@livekit/components-styles';
import { VideoConferenceRoom } from "./VideoConferenceRoom";
import { toast } from "react-toastify";

type IProps = {
    roomName: string,
    userCurrentName: string,
    toggleModal: any,
    recordEnabled?: boolean,
    visioId: number
};

type IState = { 
    roomName: string,
    participantName: string,
    audioEnabled: boolean,
    videoEnabled: boolean,
    recordEnabled:boolean,
    visioId: number,
    token: string,
};

class VideoUserRoom extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        // These properties are in the state's component in order to re-render the HTML whenever their values change
        this.state = {
            roomName: props.roomName,
            participantName: props.userCurrentName,
            audioEnabled: true,
            videoEnabled: true,
            recordEnabled: props.recordEnabled ? props.recordEnabled : true,
            visioId: props.visioId,
            token: "",
        };

        this.getToken = this.getToken.bind(this);
        this.joinRoom = this.joinRoom.bind(this);
        this.leaveRoom = this.leaveRoom.bind(this);
    }

    async componentDidMount() {
        await this.joinRoom();
        window.addEventListener('beforeunload', this.onbeforeunload);
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.onbeforeunload);
    }

    onbeforeunload(event: any) {
        this.leaveRoom();
    }

    async getToken() {
        const properties = {
            roomName: this.state.roomName,
            participantName: this.state.participantName,
        };
        const data = JSON.stringify(properties);
    
        const response = await axios.post(`${process.env.REACT_APP_IVEHTE_OPENVIDU_API}/token`, data, {
            headers: {
                'Content-Type': 'application/json'
            },
        });

        if (response.status == 200) {
            return response.data.token;
        } else {
            toast.error(response.data.message);
        }   
    }

    async joinRoom() {
        try {
            // Get a token from your application server with the room name and participant name
            const token = await this.getToken();

            //console.log("LIVEKIT Token:", token);
            this.setState({token: token});
        } catch (error) {
            toast.error("Une erreur s'est produite lors de la connexion à la salle");
            console.log("There was an error connecting to the room:", (error as Error).message);
            await this.leaveRoom();
        }
    }

    async leaveRoom() {
        this.props.toggleModal();

        // Reset the state
        this.setState({
            token: "",
        });
    }

    render() {
        return (
            <div id="room">
                <LiveKitRoom data-lk-theme="default" serverUrl={process.env.REACT_APP_OPENVIDU_LIVEKIT_URL} token={this.state.token} options={{ disconnectOnPageLeave: true }} audio={true} video={true} >
                    <div id="room-header">
                        <h2 id="room-title">{this.state.roomName}</h2>
                        <DisconnectButton onClick={this.leaveRoom}>
                            <LeaveIcon />
                        </DisconnectButton>
                    </div>
                    <VideoConferenceRoom recordRoom={this.state.recordEnabled} visioId={this.state.visioId} />
                </LiveKitRoom>
            </div>
        );
    }
}

export default VideoUserRoom;
