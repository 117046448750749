import { PostEventNotes } from 'src/interfaces/interfaces';
import axios from './AxiosInstance';

const putNotes = (visioId: number, payload: PostEventNotes) => {
    return axios.put(`api/visioconference/notes/${visioId}`, payload);
}

const VisioconferenceService = {
    putNotes
};

export default VisioconferenceService;
