import { makeStyles } from '@mui/styles';
import { baseColors, baseFontWeight } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    label: {
        textTransform: 'uppercase',
        color:  baseColors.white,
        fontSize: 8,
        fontWeight: baseFontWeight.semiBold,
    },
});

export const useNotesStyles = makeStyles((params: any) => ({
    container: {
      alignItems: 'center',
      //backgroundColor: '#1e1e1e',
      borderLeft: params?.readOnly ? ' ' : '1px solid #ffffff1a',
      display: 'block',
      width: 'auto',
    },
    header: {
      alignItems: 'center',
      display: 'flex',
      height: '20px',
      justifyContent: 'center',
      padding: '.75rem',
      position: 'relative',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'space-around',
        width: params?.readOnly ? '100%' : '93%',
        height: 'auto',
        color: '#000000',
    },
    footerBtn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '15px',
        marginBottom: '15px',
        height: '20px',
        gap: "20px"
    },
}));

export default useStyles;
