import { Grid } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { PatientContext } from 'src/providers/PatientProvider';
import ProgramItem from '../ProgramItem/ProgramItem';
import { useParams } from 'react-router-dom';
import { getProgramList } from 'src/services/Patients';
import { ProgramContext } from 'src/providers/ProgramProvider';
import ListItemLoading from 'src/components/CustomLoader/ListItemLoading';
import { useFetchUserPrograms } from 'src/hooks/useFetchUserPrograms';

export const ProgramLeftCard: FC = (props) => {
    const { selectedPatient, setCurrentPatientProgram, setSelectedPatientProgramId } = useContext(PatientContext);
    const params = useParams<any>();
    const patientId: number = +params?.id || 0;
    const [patientPrograms, setPatientPrograms] = useState<Array<any>>([]);
    const [checkedProgramId, setCheckedProgramId] = useState<number>(0);
    const { setCheckedProgram, setCheckedWorkshop, setLoadingPatientProgram, loadingPatientProgram } =
        useContext(ProgramContext);
    const { preSelectedPatientProgram, fetchPreselectedProgram } = useFetchUserPrograms({
        userId: selectedPatient?.id,
    });

    const fetchProgramData = () => {
        if (patientId > 0) {
            setLoadingPatientProgram(true);
            getProgramList(patientId)
                .then((res) => {
                    const programsData = res?.data?.data;
                    if (programsData) {
                        const firstProgram = programsData?.[0];
                        setCurrentPatientProgram(programsData);
                        setPatientPrograms(programsData);
                        setCheckedProgramId(programsData?.[0]?.program?.id);
                        setCheckedProgram(firstProgram?.program);
                        setCheckedWorkshop(firstProgram?.program?.programDetails?.workshop ?? []);
                        // setSelectedPatientWorkshopId(firstProgram?.program?.programDetails?.workshop?.at(-1)?.id);
                    }
                    setLoadingPatientProgram(false);
                })
                .catch((err) => {
                    console.log(err);
                    setLoadingPatientProgram(false);
                });
        }
    };

    useEffect(() => {
        fetchProgramData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId]);

    useEffect(() => {
        fetchPreselectedProgram();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPatient?.id]);

    useEffect(() => {
        setSelectedPatientProgramId(checkedProgramId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkedProgramId]);

    const hasProgramInProgress = preSelectedPatientProgram?.id > 0;
    const inProgressProgram = `Ce patient est inscrit dans le programme *${
        preSelectedPatientProgram?.name ?? ''
    }*, en attente de diagnostic éducatif.`;

    const renderProgramList = patientPrograms?.map((programItem, i) => {
        programItem.program.stateProgramUserTemp = programItem?.stateProgramUserTemp;
        return (
            <ProgramItem
                key={i}
                checkedProgramId={checkedProgramId}
                setCheckedProgramId={setCheckedProgramId}
                programData={programItem?.program}
            />
        );
    });

    return (
        <Grid
            xs={3}
            direction="column"
            style={{
                height: '100%',
                borderRadius: 10,
                padding: 20,
                background: baseColors.green.light,
            }}
        >
            <Grid style={{}}>
                <CustomText
                    style={{ textAlign: 'center', marginBottom: 20 }}
                    color={baseColors.green.primary}
                    fontFamily={baseFontFamily.BreeSerif}
                    fontSize={18}
                >
                    Programmes
                </CustomText>
                {loadingPatientProgram ? <ListItemLoading loadingNumber={10} /> : renderProgramList}
            </Grid>

            {hasProgramInProgress && (
                <div style={{ textAlign: 'center' }}>
                    <CustomText
                        fontSize={12}
                        style={{ marginBottom: 10 }}
                        fontFamily={baseFontFamily.OpenSansSemiBold}
                        color={baseColors.orange.primary}
                    >
                        {inProgressProgram}
                    </CustomText>
                </div>
            )}
        </Grid>
    );
};
