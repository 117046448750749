import { Dispatch, SetStateAction } from 'react';
import { ConclusionPayloadType, Objectives } from 'src/interfaces/types';
import { AXIS_CODE, AXIS_IDENTIFICATION } from 'src/utils/constants/enums';
import { ConclusionRowItem } from './SkillsFormContainer/SkillsFormContainer';
import { randomIntFromInterval, uniqByKeyValues } from 'src/utils/utils';
import { PatientService } from 'src/services/Patients';
import { toast } from 'react-toastify';
import {
    DEObservation,
    DESyntheseGoals,
    DefaultObservationPayload,
    DraftConclusion,
    OptimizedItem,
    PreselectedPatientProgram,
    ProgramWorkshops,
    ProposalAnswerSurveys,
    WorkshopItem,
} from 'src/interfaces/interfaces';
import { Motivations, OperationMessage } from 'src/utils/constants/constants';
import { getPostsListByPatient } from 'src/services/Posts';

export type ChangeGoalDuration = {
    setPayload: Dispatch<SetStateAction<any>>;
    setGoal?: any;
    value: number | string;
    axisId?: number;
    isObjectifField?: boolean;
};

const defaultAPA = {
    text: '',
    duration: '',
    axisCode: AXIS_CODE.APA,
};

const defaultDietetic = {
    text: '',
    duration: '',
    axisCode: AXIS_CODE.DIET,
};

const defaultPsycho = {
    text: '',
    duration: '',
    axisCode: AXIS_CODE.PSYCHO,
};

const defaultMedical = {
    text: '',
    duration: '',
    axisCode: AXIS_CODE.MEDICAL,
};

const defaultConclusionValue: ConclusionPayloadType = {
    isDraft: 1,
    priorityAxisCare: '',
    freins: '',
    levers: '',
    expectations: '',
    carePlan: '',
    motivation: 'Pré-contemplation',
    skills: [],
    patientId: 0,
    programId: 0,
    axisId: 1,
    conclusionWorkshops: [],
    conclusionVideos: [],
};

const durations = [
    { id: 0, duration: 'Choisir une durée' },
    { id: 1, duration: '1 (semaine)' },
    { id: 2, duration: '2 (semaines)' },
    { id: 3, duration: '3 (semaines)' },
    { id: 4, duration: '4 (semaines)' },
    { id: 5, duration: '5 (semaines)' },
    { id: 6, duration: '6 (semaines)' },
    { id: 7, duration: '7 (semaines)' },
    { id: 8, duration: '8 (semaines)' },
    { id: 9, duration: '9 (semaines)' },
];

const defaultVaules = ({ axisAPAProgram, axisDieteticProgram, axisPsychoProgram, axisMedicalProgram }: any) => {
    const defaultfetchingApa = {
        program: axisAPAProgram?.program?.id,
        user: axisAPAProgram?.user?.id,
        careTool: axisAPAProgram?.careTool?.finIndex()?.id,
        axis: 1,
        survey: axisAPAProgram?.surveyId?.id,
        valueTrame: axisAPAProgram?.valueTrame,
    };

    const defaultfetchingDietetic = {
        program: axisDieteticProgram?.program?.id,
        user: axisDieteticProgram?.user?.id,
        careTool: axisDieteticProgram?.careTool?.finIndex()?.id,
        axis: 2,
        survey: axisDieteticProgram?.surveyId?.id,
        valueTrame: axisDieteticProgram?.valueTrame,
    };

    const defaultfetchingPsycho = {
        program: axisPsychoProgram?.program?.id,
        user: axisPsychoProgram?.user?.id,
        careTool: axisPsychoProgram?.careTool?.finIndex()?.id,
        axis: 3,
        survey: axisPsychoProgram?.surveyId?.id,
        valueTrame: axisPsychoProgram?.valueTrame,
    };

    const defaultfetchingMedical = {
        program: axisMedicalProgram?.program?.id,
        user: axisMedicalProgram?.user?.id,
        careTool: axisMedicalProgram?.careTool?.finIndex()?.id,
        axis: 4,
        survey: axisMedicalProgram?.surveyId?.id,
        valueTrame: axisMedicalProgram?.valueTrame,
    };

    return {
        defaultfetchingApa,
        defaultfetchingDietetic,
        defaultfetchingPsycho,
        defaultfetchingMedical,
    };
};

const handleChangeDuration = ({ setPayload, setGoal, value, axisId, isObjectifField }: ChangeGoalDuration) => {
    setGoal?.((prev: any) => {
        if (axisId === 1) {
            return { ...prev, apa: value };
        }
        if (axisId === 2) {
            return { ...prev, diet: value };
        }
        if (axisId === 3) {
            return { ...prev, psycho: value };
        }
        return { ...prev, medical: value };
    });

    // setPayload((prev: ConclusionPayloadType) => {
    //     const objectives = prev?.objectives ?? [];
    //     const existed = objectives?.find((item: Objectives) => item.axisId === axisId);

    //     if (existed) {
    //         const filteredObjectives = objectives?.filter((item: Objectives) => item.axisId !== axisId);
    //         const res = isObjectifField
    //             ? {
    //                   ...existed,
    //                   text: value,
    //               }
    //             : {
    //                   ...existed,
    //                   duration: value,
    //               };

    //         return {
    //             ...prev,
    //             objectives: [
    //                 ...filteredObjectives,
    //                 {
    //                     ...res,
    //                 },
    //             ],
    //         };
    //     }

    //     const res = isObjectifField
    //         ? {
    //               text: value,
    //               duration: 0,
    //               axisId,
    //           }
    //         : {
    //               text: '',
    //               duration: value,
    //               axisId,
    //           };

    //     return {
    //         ...prev,
    //         objectives: [
    //             ...objectives,
    //             {
    //                 ...res,
    //             },
    //         ],
    //     };
    // });
};

const goalValue = (axisId: number, goal: any) => {
    if (axisId === 1) {
        return goal.apa;
    }
    if (axisId === 2) {
        return goal.diet;
    }
    if (axisId === 3) {
        return goal.psycho;
    }
    return goal.medical;
};

const handleSetWithDraftGoals = ({
    setGoal,
    draftConclusion,
    setPayload,
}: {
    setGoal: Dispatch<SetStateAction<DESyntheseGoals>>;
    draftConclusion: DraftConclusion;
    setPayload: Dispatch<SetStateAction<ConclusionPayloadType>>;
}) => {
    if (draftConclusion?.id > 0) {
        const axisGoal = (axisId: number) => draftConclusion?.objectives?.find((item) => item.axisId === axisId);
        setGoal({
            apa: axisGoal(AXIS_IDENTIFICATION.APA)?.text ?? '',
            diet: axisGoal(AXIS_IDENTIFICATION.DIET)?.text ?? '',
            psycho: axisGoal(AXIS_IDENTIFICATION.PSYCHO)?.text ?? '',
            medical: axisGoal(AXIS_IDENTIFICATION.MEDICAL)?.text ?? '',
        });
        const goalItems: Objectives[] = Array.from(draftConclusion?.objectives, (item: Objectives) => ({
            text: item.text,
            duration: item.duration,
            axisId: item.axisId,
        }));
        setPayload((prev) => ({
            ...prev,
            objectives: goalItems,
        }));
    }
};

const handleSetWithDraftGoalDuration = ({
    axisId,
    draftConclusion,
}: {
    axisId: number;
    draftConclusion: DraftConclusion;
}) => {
    if (draftConclusion?.id > 0) {
        const axisGoalDuration = draftConclusion?.objectives?.find((item) => item.axisId === axisId);
        return axisGoalDuration?.duration ?? 0;
    }
};

const handleSetConclusionWorkshopsPayload = ({
    setPayload,
    ids,
    axisId,
}: {
    setPayload: any;
    ids: any;
    axisId: number;
}) => {
    const conclusionWorkshops = ids?.map((id: number) => ({
        axisId,
        workshopId: id,
    }));

    setPayload((prev: any) => {
        const filtered = prev?.conclusionWorkshops?.filter((axis: any) => axis.id !== axisId);
        return { ...prev, conclusionWorkshops: [...filtered, ...conclusionWorkshops] };
    });
};

const handleFilterPayload = ({ payload }: { payload: ConclusionPayloadType }) => {
    // const uniqueWorkshops = uniqWith(
    //     payload.conclusionWorkshops,
    //     (axisA, axisB) => axisA.axisId === axisB.axisId && axisA.workshopId === axisB.workshopId,
    // );
    // const finalPayload = {
    //     ...payload,
    //     conclusionWorkshops: uniqueWorkshops,
    // };
    // return finalPayload;
};

const defaultConclusionRowItem: ConclusionRowItem = {
    id: randomIntFromInterval(1, 1000),
    value: '',
};

const handleUnlockInput = ({ setLockInput }: { setLockInput: Dispatch<SetStateAction<boolean>> }) =>
    setLockInput(false);

const handleChangeNewRowItem =
    ({ setNewRowItem }: { setNewRowItem: Dispatch<SetStateAction<ConclusionRowItem>> }) =>
    (value: string) => {
        setNewRowItem((prev: ConclusionRowItem) => ({ ...prev, value }));
    };

const handleAddNewConclusionRow =
    ({
        setInputRows,
        newRowItem,
        setLockInput,
        setNewRowItem,
    }: {
        newRowItem: ConclusionRowItem;
        setInputRows: Dispatch<SetStateAction<ConclusionRowItem[]>>;
        setLockInput: Dispatch<SetStateAction<boolean>>;
        setNewRowItem: Dispatch<SetStateAction<ConclusionRowItem>>;
    }) =>
    () => {
        setLockInput(true);
        setNewRowItem({ ...defaultConclusionRowItem, id: randomIntFromInterval(1, 1000) });
        setInputRows((prev: Array<ConclusionRowItem>) => {
            return [...prev, newRowItem];
        });
    };

const handleChangeSubForms =
    ({ setInputRows, rowId }: { setInputRows: Dispatch<SetStateAction<ConclusionRowItem[]>>; rowId: number }) =>
    (value: string) => {
        setInputRows((prev: Array<ConclusionRowItem>) => {
            const existed = prev.find((rowItem) => rowItem.id === rowId);
            const filtered = prev.filter((rowItem) => rowItem.id !== rowId);
            if (existed) {
                const newValue = {
                    ...existed,
                    value,
                };
                return [...filtered, newValue];
            }
            return prev;
        });
    };

const handleEditConclusionRow =
    ({ setLockInput }: { setLockInput: Dispatch<SetStateAction<boolean>> }) =>
    () => {
        setLockInput((prev) => !prev);
    };

const handleRemoveConclusionRow =
    ({ setInputRows, rowId }: { setInputRows: Dispatch<SetStateAction<ConclusionRowItem[]>>; rowId: number }) =>
    () => {
        setInputRows((prev: Array<ConclusionRowItem>) => {
            if (prev.find((rowItem) => rowItem.id === rowId)) {
                return prev.filter((rowItem) => rowItem.id !== rowId);
            }
            return prev;
        });
    };

const SetConclusionWorkshopsValues = ({
    items,
    isDraft,
    payload,
}: {
    items: any[];
    isDraft: number;
    payload: ConclusionPayloadType;
}) => {
    const conclusionWorkshopId = (workshopId: number) => items?.find((item: any) => item.workshop?.id === workshopId);
    const res = payload.conclusionWorkshops?.map((workshopItem: any) => {
        if (Boolean(isDraft)) {
            const value = conclusionWorkshopId(workshopItem.workshopId);
            return {
                id: value?.id,
                axisId: workshopItem?.axisId,
                workshopId: workshopItem?.workshopId,
            };
        } else {
            return {
                axisId: workshopItem?.axisId,
                workshopId: workshopItem?.workshopId,
            };
        }
    });

    return res;
};

function RemoveEmptyNullDataFromPayload(payload: ConclusionPayloadType) {
    const filteredSkills = payload?.skills?.filter((skill) => skill?.goalIds?.length > 0) ?? [];
    const filteredConclusionWorkshops = payload?.conclusionWorkshops?.filter((wrkspId) => wrkspId !== null) ?? [];
    const filteredConclusionVideos = payload?.conclusionVideos?.filter((articleId) => articleId !== null) ?? [];
    const filteredMotivation = payload?.motivation === null ? '' : payload?.motivation;

    return {
        ...payload,
        skills: filteredSkills,
        conclusionWorkshops: filteredConclusionWorkshops,
        conclusionVideos: filteredConclusionVideos,
        motivation: filteredMotivation,
    };
}

const performAddConclusion = ({
    defaultConclusion,
    payload,
    setPayload,
    setAddingResume,
    setSavingResume,
    setLockInput,
    setUpdateList,
    setShowForm,
    setShowConfirm,
    isDraft,
    draftConclusion,
    refectConclusions,
}: {
    defaultConclusion: any;
    payload: ConclusionPayloadType;
    setPayload: any;
    setAddingResume: any;
    setLockInput: any;
    isDraft: number;
    setUpdateList: Dispatch<SetStateAction<boolean>>;
    setShowForm: Dispatch<SetStateAction<boolean>>;
    setShowConfirm: Dispatch<SetStateAction<boolean>>;
    setSavingResume: Dispatch<SetStateAction<boolean>>;
    draftConclusion: DraftConclusion;
    refectConclusions?: any;
}) => {
    Boolean(isDraft) ? setSavingResume(true) : setAddingResume(true);
    const message = Boolean(isDraft) ? 'Conclusion bien enregistrée' : 'La conclusion a été validée';
    const errorMessage = Boolean(isDraft) ? "Échec lors de l'enregistrement" : 'Échec lors de validation';

    let finalPayload = {
        ...payload,
    };

    if (draftConclusion?.id > 0) {
        finalPayload = { ...finalPayload, id: draftConclusion.id };
    }

    const filteredPayload = RemoveEmptyNullDataFromPayload(finalPayload);

    function success() {
        toast.success(message);
        setPayload(defaultConclusion);
        setAddingResume(false);
        setSavingResume(false);
        setLockInput(false);
        setUpdateList((prev) => !prev);
        setShowForm((prev) => !prev);
        setShowConfirm((prev) => !prev);
    }

    function failed(err: string) {
        toast.error(errorMessage);
        setAddingResume(false);
        setSavingResume(false);
        setLockInput(false);
        setShowConfirm((prev) => !prev);
    }

    setLockInput(true);

    PatientService.postConclusions({ ...filteredPayload, isDraft })
        .then(() => {
            refectConclusions?.();
            success();
        })
        .catch((err) => failed(err));
};

const handleShowMore =
    ({ setShowAllItems }: { setShowAllItems: Dispatch<SetStateAction<boolean>> }) =>
    () => {
        setShowAllItems((prev) => !prev);
    };

const defaultObservationPayload: DefaultObservationPayload = {
    programId: 0,
    educationalDiagnosisId: 0,
    observations: [],
};

const handlePostDEObservation =
    ({
        payload,
        setPayload,
        setPosting,
        setUpdateList,
    }: {
        payload: DefaultObservationPayload;
        setPayload: Dispatch<SetStateAction<DefaultObservationPayload>>;
        setPosting: Dispatch<SetStateAction<boolean>>;
        setUpdateList: Dispatch<SetStateAction<boolean>>;
    }) =>
    () => {
        setPosting(true);
        PatientService.postDEObservationByAxis(payload)
            .then(() => {
                toast.success(OperationMessage.success);
                setPayload(defaultObservationPayload);
                setPosting(false);
                setUpdateList((prev) => !prev);
            })
            .catch(() => {
                toast.error(OperationMessage.error);
                setPosting(false);
            });
    };

const handleSetDEObservation =
    ({
        setPayload,
        setObservation,
        userSurveyId,
    }: {
        setPayload: Dispatch<SetStateAction<DefaultObservationPayload>>;
        setObservation: Dispatch<SetStateAction<string>>;
        userSurveyId: number;
    }) =>
    (value: string) => {
        setObservation(value);
        setPayload((prev: DefaultObservationPayload) => {
            const existed = prev.observations.find(
                (observation: DEObservation) => observation.userSurveyId === userSurveyId,
            );
            const filtered = prev.observations.filter(
                (observation: DEObservation) => observation.userSurveyId !== userSurveyId,
            );

            const observation = {
                userSurveyId,
                observation: value,
            };

            if (prev.observations?.length) {
                if (existed) {
                    const newObservation = {
                        ...existed,
                        observation: value,
                    };
                    return {
                        ...prev,
                        observations: [...filtered, { ...newObservation }],
                    };
                }

                return { ...prev, observations: [...prev.observations, { ...observation }] };
            } else {
                return { ...prev, observations: [{ ...observation }] };
            }
        });
    };

const responseTypeValue = ({ name, response }: { name: string; response: number | string }) => {
    if (name === 'boolean') {
        console.log({ response });

        if (+response === 1) {
            return 'Oui';
        } else if (response === '') {
            return '(Pas de réponse)';
        }
        return 'Non';
    }else {
        return response;
    }
};

const responseDieteticValue = ({ surveyId, responseId, proposalAnswerSurveys }: { surveyId: number; responseId: number | string; proposalAnswerSurveys: ProposalAnswerSurveys[] | undefined }) => {
    if(proposalAnswerSurveys){
        const proposalAnswer = proposalAnswerSurveys.find((item: ProposalAnswerSurveys) => item.proposalAnswer.id === Number(responseId))
        if(proposalAnswer){
            return proposalAnswer.proposalAnswer.name
        }
    }
    return ''
};

const disablePostObservationBtn = ({ payload, posting }: { payload: DefaultObservationPayload; posting: boolean }) =>
    !payload?.observations?.length ||
    (payload?.observations?.length && payload?.observations?.every((item) => item.observation?.trim() === '')) ||
    posting;

const handleCheckItem =
    ({
        isVideos,
        contentId,
        setPayload,
        axisId,
    }: {
        contentId: number;
        axisId: number;
        setPayload: Dispatch<SetStateAction<ConclusionPayloadType>>;
        isVideos?: boolean;
    }) =>
    () => {
        // setPayload((prev: ConclusionPayloadType) => {
        // if (isVideos) {
        //     const existed = prev?.conclusionVideos.find((workshop) => workshop.videosId === contentId);
        //     const filtered = prev?.conclusionVideos.filter((workshop) => workshop.videosId !== contentId);
        //     const newValue = {
        //         videosId: contentId,
        //     };
        //     if (prev?.conclusionVideos.length > 0) {
        //         if (existed) {
        //             return { ...prev, conclusionVideos: filtered };
        //         } else {
        //             return { ...prev, conclusionVideos: [...prev?.conclusionVideos, newValue] };
        //         }
        //     } else {
        //         return { ...prev, conclusionVideos: [newValue] };
        //     }
        // } else {
        // const existed = prev?.conclusionWorkshops?.find((workshop) => workshop?.workshopId === contentId);
        // const filtered = prev?.conclusionWorkshops?.filter((workshop) => workshop?.workshopId !== contentId);
        // const newValue = {
        //     axisId,
        //     workshopId: contentId,
        // };
        // if (prev?.conclusionWorkshops?.length) {
        //     if (existed) {
        //         return { ...prev, conclusionWorkshops: filtered };
        //     } else {
        //         return { ...prev, conclusionWorkshops: [...prev?.conclusionWorkshops, newValue] };
        //     }
        // } else {
        //     return { ...prev, conclusionWorkshops: [newValue] };
        // }
        // }
        // });
    };

const handleFetchPostsPatient = ({
    setFetchingVideos,
    patientId,
    setVideos,
}: {
    patientId: number;
    setFetchingVideos: Dispatch<SetStateAction<boolean>>;
    setVideos: Dispatch<SetStateAction<Array<any>>>;
}) => {
    setFetchingVideos(true);
    getPostsListByPatient(patientId)
        .then((res: any) => {
            const responses = res;
            const optimized = Array.from(responses ?? [], (item: any) => ({ id: item.id, name: item.title }));
            setVideos(optimized ?? []);
            setFetchingVideos(false);
        })
        .catch(() => {
            setFetchingVideos(false);
        });
};

const handleSetPayloadWithSavedConclusion = ({
    draftConclusion,
    setPayload,
    patientId,
    programId,
    setMotivation,
}: {
    patientId: number;
    programId: number;
    draftConclusion: DraftConclusion;
    setPayload: Dispatch<SetStateAction<ConclusionPayloadType>>;
    setMotivation: Dispatch<SetStateAction<OptimizedItem>>;
}) => {
    const selctedMotivation = Motivations.find(
        (motivation: OptimizedItem) => motivation.name === draftConclusion?.motivation,
    );
    setMotivation(selctedMotivation as OptimizedItem);

    setPayload((prev) => ({
        ...prev,
        isDraft: 1,
        priorityAxisCare: draftConclusion?.priorityAxisCare ?? '',
        freins: draftConclusion?.freins ?? '',
        levers: draftConclusion?.levers ?? '',
        expectations: draftConclusion?.expectations ?? '',
        carePlan: draftConclusion?.carePlan ?? '',
        patientId,
        programId,
        motivation: draftConclusion?.motivation,
    }));
};

const handleSetDefaultInputRows = ({
    draftConclusion,
    setInputRows,
}: {
    draftConclusion: DraftConclusion;
    setInputRows: Dispatch<SetStateAction<ConclusionRowItem[]>>;
}) => {
    const items = Array.from(draftConclusion?.skills ?? [], (skill, index) => ({
        id: index,
        value: skill.text,
    }));
    setInputRows(items);
};

const handleSetWorkshopConclusion = ({
    isVideos,
    videos,
    setPayload,
    workshops,
    setExistedIds,
    program,
    draftConclusion,
}: {
    isVideos?: boolean;
    videos?: Array<any>;
    setPayload: Dispatch<SetStateAction<ConclusionPayloadType>>;
    workshops: WorkshopItem[];
    setExistedIds: Dispatch<SetStateAction<number[]>>;
    program: PreselectedPatientProgram;
    draftConclusion: DraftConclusion;
}) => {
    const ids: any = isVideos ? videos?.map((item: any) => item.id) : program?.workshops;
    const contentId = (id: number) => workshops?.find((item) => item.id === id);
    const draftConclusionWorkshopIds = draftConclusion?.conclusionWorkshops?.map(
        (workshopItem: any) => workshopItem?.workshop?.id,
    );
    const filterdDraftConclusionWorkshopIds = draftConclusionWorkshopIds?.filter(
        (workshopId) => workshopId > 0 && workshopId !== undefined,
    );
    const idsToUseInPayload = draftConclusion?.id > 0 ? filterdDraftConclusionWorkshopIds : ids;

    setPayload((prev) => {
        if (isVideos) {
            return {
                ...prev,
                conclusionVideos: ids?.map((item: any) => ({
                    videosId: item,
                })),
            };
        }
        return {
            ...prev,
            conclusionWorkshops: idsToUseInPayload?.map((item: any) => ({
                axisId: contentId(item)?.axisId,
                workshopId: item,
            })),
        };
    });
    setExistedIds(ids ?? []);
};

const handleExisteIds = ({
    isVideos,
    payload,
    setExistedIds,
}: {
    isVideos?: boolean;
    payload: ConclusionPayloadType;
    setExistedIds: Dispatch<SetStateAction<number[]>>;
}) => {
    // const ids: any = isVideos
    //     ? payload?.conclusionVideos?.map((item) => item.videosId)
    //     : payload?.conclusionWorkshops?.map((item) => item.workshopId);
    // setExistedIds(ids ?? []);
};

const handleCheckUserProgram = ({
    optimizedPrograms,
    preSelectedPatientProgram,
    setPayload,
    setProgram,
}: {
    setPayload: Dispatch<SetStateAction<ConclusionPayloadType>>;
    preSelectedPatientProgram: any;
    optimizedPrograms: ProgramWorkshops[];
    setProgram: Dispatch<SetStateAction<ProgramWorkshops>>;
}) => {
    const userProgram = optimizedPrograms?.find((item) => item?.id === preSelectedPatientProgram?.id);
    userProgram?.id && setPayload((prev) => ({ ...prev, programId: userProgram?.id }));
    setProgram(userProgram || ({} as ProgramWorkshops));
};

const SetToUniqDraftData = ({
    draftConclusion,
    isWorkshops,
}: {
    draftConclusion: DraftConclusion;
    isWorkshops?: boolean;
}) => {
    if (isWorkshops) {
        const workshopFromDraft =
            draftConclusion?.conclusionWorkshops?.map((workshopItem: any) => workshopItem?.workshop) ?? [];
        return uniqByKeyValues({ items: workshopFromDraft, key: 'id' });
    }

    const articleFromDraft = draftConclusion?.conclusionVideos?.map((articleItem: any) => articleItem?.article) ?? [];
    return uniqByKeyValues({ items: articleFromDraft, key: 'id' });
};

const performUpdateUserSurveys = ({payloadUpdateResponse, setUpdatingUserSurvey, setShowUpdateUserSurvey, setUpdateList}: any) => {
    setUpdatingUserSurvey(true);
    PatientService.putUserSurvey(payloadUpdateResponse)
    .then(() => {
        toast.success(OperationMessage.success);
        setUpdatingUserSurvey(false);
        setShowUpdateUserSurvey(false);
        setUpdateList((prev:boolean) => !prev);
    })
    .catch((err) => {
        toast.error(OperationMessage.error);
        setUpdatingUserSurvey(false)
        console.log(err);
    })
}

export {
    SetToUniqDraftData,
    handleCheckUserProgram,
    handleExisteIds,
    handleSetWorkshopConclusion,
    handleSetWithDraftGoalDuration,
    handleSetWithDraftGoals,
    handleSetDefaultInputRows,
    handleSetPayloadWithSavedConclusion,
    handleFetchPostsPatient,
    handleCheckItem,
    responseDieteticValue,
    disablePostObservationBtn,
    responseTypeValue,
    handleSetDEObservation,
    handlePostDEObservation,
    defaultObservationPayload,
    handleShowMore,
    performAddConclusion,
    handleUnlockInput,
    handleChangeNewRowItem,
    handleEditConclusionRow,
    handleRemoveConclusionRow,
    handleChangeSubForms,
    defaultConclusionRowItem,
    handleAddNewConclusionRow,
    handleFilterPayload,
    handleSetConclusionWorkshopsPayload,
    handleChangeDuration,
    defaultAPA,
    defaultDietetic,
    defaultPsycho,
    defaultMedical,
    defaultConclusionValue,
    durations,
    defaultVaules,
    goalValue,
    performUpdateUserSurveys
};
